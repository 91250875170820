import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Box, Tooltip } from "@material-ui/core";
import { withNavBars } from "../../HOCs";
import ContentMapping from "./contentMapping";
import { default as FormRenderer } from "../formRenderer";
import { actions } from "hcc-binder";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FormRendererParent, ViewForm } from "form-configurator";
import FormRenCheck from "./formRenCheck";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  stepper: {
    backgroundColor: "transparent",
    padding: "8px",
    // height: "100%",
  },
  button: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Basic Details", "Content Mapping"];
}

function VerticalLinearStepper(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const [isSubmitted, setIsSubmitted] = React.useState(true);
  const steps = getSteps();
  const [skipped, setSkipped] = React.useState(new Set());
  const [data, setdata] = React.useState({});
  const [consumerState, setConsumerState] = React.useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: null,
    Address: "",
    Pincode: null,
    City: "cbe",
    State: "",
    Country: "",
    Consumer_ID: "",
    Content_Id: [],
  });

  const [questionDatas, setQuestionData] = React.useState([]);
  const [formData, setFormData] = React.useState([]);
  const [entry_id, setEntryid] = React.useState("");

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  React.useEffect(() => {
    async function quesFunc() {
      var entryid = localStorage.getItem("entry_id");
      console.log("EntryIDsss -", entryid);
      let questions = {
        db_name: "hcc",
        entity: "questions",
        filter: `questions.form_id=='c3c176b9-5369-4a71-b0c6-bfa043ad8994'&& questions.is_active==true && questions.activestatus==true`,
        return_fields:
          "{questionId:questions.question_id, questionName:questions.question_name}",
      };

      let config = {
        method: "POST",
        url: `https://arangodbservice.dev.ainqaplatform.in/api/read_documents`,
        headers: {
          "Content-Type": "application/json",
        },
        data: questions,
      };

      var questionData = await axios(config).then((res) => {
        return res.data.result;
      });

      // console.log("questionData -", questionData);
      setQuestionData([...questionData]);

      let formEntires = {
        db_name: "hcc",
        entity: "form_entries",
        filter: `form_entries.form_id=='c3c176b9-5369-4a71-b0c6-bfa043ad8994'&&form_entries.entry_id=='${entryid}'&&form_entries.is_active==true && form_entries.activestatus==true`,
        return_fields: "form_entries.answers",
      };

      let configEn = {
        method: "POST",
        url: `https://arangodbservice.dev.ainqaplatform.in/api/read_documents`,
        headers: {
          "Content-Type": "application/json",
        },
        data: formEntires,
      };

      var formEn = await axios(configEn).then((res) => {
        return res.data.result;
      });

      // console.log("FormEn -", formEn);
      setFormData(...formEn);
    }

    quesFunc();

    // debugger;
    // let enId = localStorage.getItem("entry_id");
    // setEntryid(enId);

    // async function quesFunc() {

    //   var entryid = localStorage.getItem("entry_id");
    //   console.log("EntryIDsss -", entryid)
    //   let questions = {
    //     db_name: "hcc",
    //     entity: "questions",
    //     filter: `questions.form_id=='c3c176b9-5369-4a71-b0c6-bfa043ad8994'&& questions.is_active==true && questions.activestatus==true`,
    //     return_fields:
    //       "{questionId:questions.question_id, questionName:questions.question_name}",
    //   };

    //   let config = {
    //     method: "POST",
    //     url: `https://arangodbservice.dev.ainqaplatform.in/api/read_documents`,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: questions,
    //   };

    //   var questionData = await axios(config).then((res) => {
    //     return res.data.result;
    //   });

    //   // console.log("questionData -", questionData);
    //   setQuestionData([...questionData]);

    //   let formEntires = {
    //     db_name: "hcc",
    //     entity: "form_entries",
    //     filter: `form_entries.form_id=='c3c176b9-5369-4a71-b0c6-bfa043ad8994'&&form_entries.entry_id=='${entryid}'&&form_entries.is_active==true && form_entries.activestatus==true`,
    //     return_fields: "form_entries.answers"
    //   };

    //   let configEn = {
    //     method: "POST",
    //     url: `https://arangodbservice.dev.ainqaplatform.in/api/read_documents`,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: formEntires,
    //   };

    //   var formEn = await axios(configEn).then((res) => {
    //     return res.data.result;
    //   });

    //   // console.log("FormEn -", formEn);
    //   setFormData(...formEn);
    // }


    // quesFunc();

  }, []);

  const [contentState, setContentState] = React.useState([]);

  // console.log("FormDatas - ", formData);
  // console.log("questionData", questionDatas);

  // let submitData = formData.map((formD) => {
  //   // for (let i = 0; i < questionDatas.length; i++) {
  //   //   if (questionDatas[i].questionId === formD.answer.id) {
  //   //     if (`${questionDatas[i].questionName}` === "Providers Name") {
  //   //       setConsumerState({ ...consumerState, First_Name: formD.answer.answer })
  //   //     }
  //   //     if (questionDatas[i].questionName === "Last Name") {
  //   //       setConsumerState({ ...consumerState, Last_Name: formD.answer.answer })
  //   //     }
  //   //     if (questionDatas[i].questionName === "Mobile No") {
  //   //       setConsumerState({ ...consumerState, Phone_Number: formD.answer.answer.number })
  //   //     }
  //   //     // setConsumerState({ ...consumerState, questionDatas[i].questionName: formD.answer.answer });
  //   //   }
  //   // }
  //   // let quesAns = questionDatas.map((ques) => {
  //   //   if (ques.questionId === formD.answer.id) {
  //   //     return { questionName: ques.questionName, answer: formD.answer.answer }
  //   //   } else {
  //   //     return;
  //   //   }
  //   // })
  // });
  let submitData = formData?.map((formD) => {

    // for (let i = 0; i < questionDatas.length; i++) {
    //   if (questionDatas[i].questionId === formD.answer.id) {
    //     if (`${questionDatas[i].questionName}` === "Providers Name") {
    //       setConsumerState({ ...consumerState, First_Name: formD.answer.answer })
    //     }
    //     if (questionDatas[i].questionName === "Last Name") {
    //       setConsumerState({ ...consumerState, Last_Name: formD.answer.answer })
    //     }
    //     if (questionDatas[i].questionName === "Mobile No") {
    //       setConsumerState({ ...consumerState, Phone_Number: formD.answer.answer.number })
    //     }
    //     // setConsumerState({ ...consumerState, questionDatas[i].questionName: formD.answer.answer });
    //   }
    // }
    // let quesAns = questionDatas.map((ques) => {
    //   if (ques.questionId === formD.answer.id) {
    //     return { questionName: ques.questionName, answer: formD.answer.answer }
    //   } else {
    //     return;
    //   }
    // })

  })


  // console.log("consumerState - ", consumerState);

  const handleNext = async () => {
    // debugger;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    console.log("ContentState -", contentState);
    let params = {
      First_Name: "Hcc",
      Last_Name: "Platform",
      Email: "admin@ainqaplatform.in",
      Phone_Number: 1234567,
      Address: "11/22",
      Pincode: 638002,
      City: "cbe",
      State: "TamilNadu",
      Country: "India",
      Consumer_ID: "535d730c-9057-48c1-9263-267bbb6f0205",
    };
  };

  console.log("EntryIDFFF - ", entry_id);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormRenCheck />
          // <FormRendererParent
          //   id="c3c176b9-5369-4a71-b0c6-bfa043ad8994"

          //   onSaveForm={(data) => {
          //     // console.log(data, "data")
          //     setdata(data);
          //   }}
          //   answer={data?.answer}
          //   btnName="save"
          //   submitBtnBottom="Right"
          //   negativeBtn="Cancel"
          //   cancelPresent={false}
          //   headerPresent={true}
          //   formTitle={true}




          // />
          // <FormRenderer
          //   id="c3c176b9-5369-4a71-b0c6-bfa043ad8994"
          //   secToShow={[0, null]}
          //   secName={0}
          //   entryId={entry_id}
          //   isSubmitted={isSubmitted}
          //   setIsSubmitted={setIsSubmitted}
          // />
        );
      case 1:
        return (
          <ContentMapping
            contentState={contentState}
            contentData={props?.contentData}
            setContentState={setContentState}
          />
        );
      case 2:
        return;
      default:
        return "Unknown step";
    }
  }

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        style={{ height: "52px", padding: "0px" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        style={{
          height: "calc(100% - 142px)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        {getStepContent(activeStep, state)}
      </Box>
      <Box
        style={{
          display: "flex",
          height: "52px",
          alignItems: "center",
        }}
      >
        <div style={{ flex: "1 1 auto" }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
        </div>

        {/* {isStepOptional(activeStep) && (
              <Button
                color="inherit"
                // onClick={handleSkip}
              >
                Skip
              </Button>
            )}
    
            {activeStep === 0 && (
              <Tooltip title="Update Client Details" placement="top" arrow>
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={() => upsertUpdateClient()}
                  style={{ marginRight: "4px" }}
                >
                  Update
                </Button>
              </Tooltip>
            )} */}
        <Button
          variant="contained"
          color="primary"
          disabled={false}
          onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </div>
  );
}

export default VerticalLinearStepper;
