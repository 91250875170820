import React from "react";
import { withRouter } from "react-router-dom";
import { GeneralMaster } from "atp-general-master";
import { withNavBars } from "../../HOCs";
import { withTheme } from "@material-ui/core";

const Generalmaster = (props) => {
    return(
<div>

<GeneralMaster URl="https://arangodbservice.dev.ainqaplatform.in" db_name="hcc" metadataId="e2885a17-27d8-4ee0-abf3-e1ec1e6cb8b2" metadata_dbname ="ATP_Metadata_Dev" theme={props?.theme} />

  </div> 
  )
}

export default  withNavBars(withTheme(Generalmaster));