import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, apis } from "hcc-binder";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
} from "@material-ui/core";
import { Select } from "../../components";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    marginBottom: "10px",
  },
  header: {
    padding: "10px 20px",
    color: "#000000DE", //"#323232",

    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 1.5,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    // letterSpacing: "0.00938em",
    margin: "0px 0px 5.6px",
  },
}));

const ContentMapping = (props) => {
  const classes = useStyles();

  const [state, setState] = useState([]);

  const location = useLocation();

  const [options, setOptions] = React.useState();
  const [start, setStart] = React.useState(false);
  const [fill, setFill] = React.useState();

  const onChange = (data) => {
    props?.setContentState([...data]);
  };

  useEffect(() => {
    setOptions(
      props?.contentData?.map((disData) => {
        return { label: disData.Content_Name, value: disData.Content_ID };
      })
    );

    let filData = [];

    for (let i = 0; i < props?.contentState.length; i++) {
      for (let j = 0; j < props?.contentData.length; j++) {
        if (props?.contentState[i] === props?.contentData[j].Content_ID) {
          filData.push({
            label: props?.contentData[j].Content_Name,
            value: props?.contentData[j].Content_ID,
          });
        }
      }
    }

    console.log("FilterDatts -", filData);

    props?.setContentState([...filData]);
  }, []);

  console.log("OptionsState -", options);
  //   console.log("stateCons -", state);

  return (
    <div className={classes.root}>
      <Grid
        container
        // direction='column'
        // justify='flex-start'
        // alignItems='stretch'
      >
        <Grid item>
          <Typography
            /* style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} */
            variant="h6"
            className={classes.header}
          >
            {"Content Mapping"}
          </Typography>
        </Grid>

        <Grid item>
          <Select
            name="contents"
            //   error={state.errorValidation.error.icd10}
            //   helperText={
            //     state.errorValidation.error.icd10 === true ? Message?.requiredMessage : ''
            //   }
            // label={"contents"}
            id="contents"
            multiple
            value={props?.contentState ? props?.contentState : []}
            handleselect={(data) => onChange(data)}
            option={options}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContentMapping;
