import React from "react";
import { withRouter } from "react-router-dom";
import { Authentication } from "atp-authentication";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
    return (
        <div>
            <Authentication showSignUp={false} showReset={true} />
        </div>
    );
};

export default withRouter(Login);
