import { default as FormRenderer } from "./formRenderer";
import { giveMeRoleDataSecurity } from "../../utils";
import { actions } from "hcc-binder";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNavBars } from "../../HOCs";
import copy from "fast-copy";
import { v4 as uuidv4 } from "uuid";
// import { FormRenderer } from "atp-form-renderer";

const { GET_RENDER_JSON } = actions;

const FormRendererParent = (props) => {
  console.log(props, "props");

  let formId = props.id;
  const dispatch = useDispatch();

  React.useEffect(() => {
    // getFormDetail();
    props?.GET_FORM_DATA();
    getFormDetail(props);
  }, []);

  console.log(actions, "ACTIONS");

  const formData = useSelector((state) => state?.formSlice?.getFormData);
  const renderJson = useSelector(
    (state) => state?.renderSlice?.getRenderJson.data
  );
  console.log("FormData", formData);
  console.log("RenderJson", renderJson);

  const {
    form_detail = {},
    tree = [],
    form = [],
    bulkAnswers = {},
  } = renderJson;

  const [entryStateId, setEntryStateId] = useState("");

  console.log("propsEntry", props?.entryId);

  useEffect(() => {
    debugger;
    if (props?.entryId?.length > 0) {
      setEntryStateId(props.entryId);
      localStorage.setItem("entry_id", props.entryId);
    } else if (entryStateId?.length > 0) {
      localStorage.setItem("entry_id", entryStateId);
      setEntryStateId(entryStateId);
    } else {
      let entryId = uuidv4();
      setEntryStateId(entryId);
      localStorage.setItem("entry_id", entryId);
    }
  }, []);

  console.log("EntryID - ", entryStateId);

  console.log("form_detail", form_detail);
  const getFormDetail = (props) => {
    const roleDataSecurity = giveMeRoleDataSecurity(props?.userData);
    const createdBy = props?.userData?.user_profile_id;

    let id = formId;
    let entryId = props?.entryId;
    let isInvestigator = props?.location?.state?.isInvestigator ?? false;

    let params = {
      formId: id,
      entryId: entryId,
      isInvestigator,
      typeViewer: props?.title,
      secToShow: [0, null],
      type: 0,
      forms: roleDataSecurity.form?.[0] === "all" ? [] : roleDataSecurity.form,
      countryIds:
        roleDataSecurity.country?.[0] === "all" ? [] : roleDataSecurity.country,
      level: {
        level1:
          roleDataSecurity.level1?.[0] === "all" ? [] : roleDataSecurity.level1,
        level2:
          roleDataSecurity.level2?.[0] === "all" ? [] : roleDataSecurity.level2,
        level3:
          roleDataSecurity.level3?.[0] === "all" ? [] : roleDataSecurity.level3,
        level4:
          roleDataSecurity.level4?.[0] === "all" ? [] : roleDataSecurity.level4,
        level5:
          roleDataSecurity.level5?.[0] === "all" ? [] : roleDataSecurity.level5,
        level6:
          roleDataSecurity.level6?.[0] === "all" ? [] : roleDataSecurity.level6,
        level7:
          roleDataSecurity.level7?.[0] === "all" ? [] : roleDataSecurity.level7,
        level8:
          roleDataSecurity.level8?.[0] === "all" ? [] : roleDataSecurity.level8,
        level9:
          roleDataSecurity.level9?.[0] === "all" ? [] : roleDataSecurity.level9,
        level10:
          roleDataSecurity.level10?.[0] === "all"
            ? []
            : roleDataSecurity.level10,
        level11:
          roleDataSecurity.level11?.[0] === "all"
            ? []
            : roleDataSecurity.level11,
        level12:
          roleDataSecurity.level12?.[0] === "all"
            ? []
            : roleDataSecurity.level12,
      },
    };

    if (createdBy) {
      params.created_by = createdBy;
    }

    dispatch(GET_RENDER_JSON(params));
  };

  let copyForm = copy(form);

  // console.log("props", props);

  const giveMeAnswer = (bulkAnswers) => {
    let answer = {};
    bulkAnswers?.answers?.map((ans, index) => {
      return (answer[ans?.question_id] = ans.answer);
    });

    return answer;
  };

  return (
    <div id="divOne">
      <FormRenderer
        form_detail={form_detail}
        tree_view={tree}
        forms={copyForm}
        entry_id={entryStateId}
        parent_form_id={formId}
        isEdit={props?.entryId ? true : false}
        secToShow={props?.secToShow}
        secName={props?.secName}
        answer={giveMeAnswer(bulkAnswers)}
        isSubmitted={props?.isSubmitted}
        setIsSubmitted={props?.setIsSubmitted}
      />

      {/*<FormRenderer
       form_detail={form_detail}
            transferDetails={transferDetails}
            tree_view={tree}
            forms={this.copiedForm}
            parent_form_id={formId}
            entry_id={entryId}
            isEdit={entryId ? true : false}
            isReadyOnly={isReadyOnly}
            answer={this.giveMeAnswer(bulkAnswers)}
            isInvestigator={isInvestigator}
            dataSecurityMappedCountryAndSelectedlevel={
              dataSecurityMappedCountryAndSelectedlevel
            }
            mappedLocations={mappedLocations}
            toggleButtonStatus={
              entryId && this.canIshowForm()
                ? this?.giveMeStatusData(statusArray, bulkAnswers)
                : {}
            }
            secToShow={this?.props?.secToShow}
            secName={this?.props?.secName}
            getPatientDetails={this.getPatientDetails(answers)}
            entryPatientDetails={copy(patientDetail)}  
      />*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  form_detail: state?.renderSlice?.getRenderJson,
});

export default connect(mapStateToProps, actions)(FormRendererParent);
