import React, { useEffect, useState } from "react";
import MySwaggerUI from "./myswaggerui";
import { withNavBars } from "../../HOCs";
import { makeStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from "react-redux";
import { actions, apis } from "hcc-binder";
import LeftTabs from "./leftTab/leftTabs";
import { Margin } from "@mui/icons-material";

const styles = makeStyles((theme) => ({
    root:{
   overflow:"hidden",
    margin:"10px"
    },
    SwaggerUI: {
        backgroundColor: "#fff",
        border:"1px solid #e7dfdf",
        boxShadow: "0 10px 6px -6px #777",
        borderRadius:"12px"
    },
    leftHeading: {
        fontSize: "19px",
        padding: "10px",
        fontWeight: "bold",
        textTransform:"uppercase"
    },
}));


function Swagger() {
    const classes = styles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => ({
        getallcontents: state?.providerSlice?.getcontents?.data?.result,
    }));
    const [value, setValue] = React.useState(0);
    const [datasss, setdata] = useState({});
    useEffect(() => {
        dispatch(actions.getallcontents());

    }, []);

    useEffect(() => {
        if (selector?.getallcontents !== undefined) {
            if (selector?.getallcontents?.length > 0) {
                fetchdata(selector?.getallcontents[0])
            }

        }

    }, [selector?.getallcontents]);

    const handleChange = async (data) => {

        fetchdata(data)
        setValue(value);
    };

    const fetchdata = async (data) => {
        const datasss = await dispatch(actions.getallserviceid({ contentid: data?._id }));
        const dataValue = datasss?.payload?.data?.result;
        getdData(dataValue, data);
    }

    const getdData = (data1, data) => {
        let paths = ""; 
        let method = "" 
        let contentName = "" ;
        let result = "" ;
        let obj = {};

        if (data1?.length > 0) {
            data1?.map(vaulesss => {
                paths = `/${vaulesss?.Endpoint}`;
                method = `${vaulesss?.Method}`
                contentName = data?.Content_Name.toUpperCase();
                result = method.toLowerCase();
                let objvalue = {
                        [result]: {
                            "tags": [
                                `SWAGGER - ${contentName}`
                            ],
    
                            "consumes": [
                                "application/json"
                            ],
                            "produces": [
                                "application/json"
                            ],
                            "parameters": [
                                {
                                    "in": "body",
                                    "name": "map",
                                    "description": "map",
                                    "required": true,
                                    "schema": vaulesss?.Request_Schema
                                }
                            ],
                            "responses": {
                                "200": {
                                    "description": "OK",
                                    "schema": {
                                        "type": "string"
                                    }
                                },
                                "201": {
                                    "description": "Created"
                                },
                                "401": {
                                    "description": "Unauthorized"
                                },
                                "403": {
                                    "description": "Forbidden"
                                },
                                "404": {
                                    "description": "Not Found"
                                }
                            },
                            "deprecated": false
                        }
                    ,
                }
                obj[paths] = objvalue
            })
            const value = {
                "swagger": "2.0",
                "host": `${data1[0]?.Service_URL}`,
                "basePath": "/api",
                "paths": {...obj},
            }
            console.log("value" , value)
            setdata(value)
        }

        else {
            setdata({})
        }

    }


    return (
        <div className={classes.root}>
            <Grid container className={classes.rootTable} spacing={2}>
                <Grid item xs={3}>
                    <LeftTabs
                        contentData={selector?.getallcontents}
                        value={value}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={9}>
                    <MySwaggerUI data={datasss} classes={classes} />
                </Grid >
            </Grid>

        </div>
    );
}


export default withNavBars(Swagger); 