import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { LinearProgressWithLabel } from "../../components";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Prompt } from "react-router-dom";
import { ComponentToRender } from "./componentToRender";
import { getMobileLimitBasedOnCC, giveMeRoleDataSecurity } from "../../utils";
import {
  checkStringValidations,
  checkNumericValidations,
  checkDobValidations,
} from "./checkValidations";

import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { actions } from "hcc-binder";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formTitle: {
    fontWeight: "bold",
    // color: theme.palette.primary.main
  },
  menuIcon: {
    color: "black",
  },
  skipBtn: {
    // color: theme.palette.hash.primary
  },
  header: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 3,
  },
  uiComponentWrapper: {
    marginTop: 10,
    padding: "8px 28px",
  },
  savebtn: {
    padding: "6px 12px",
    borderRadius: 6,
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.light,
    "&:hover": {
      // background: theme.palette.primary.light,
      // color: theme.palette.text.dark,
      // backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.light,
    },
  },
  draftbtn: {
    padding: "6px 12px",
    borderRadius: 6,
    marginLeft: "5px",
    marginRight: "5px",
    // color: theme.palette.secondary.main,
    // borderColor: theme.palette.secondary.main,
  },
  nxtBtn: {
    fontSize: "0.8125rem",
    display: "flex",
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.light,
    // '&:hover': {
    //   background: theme.palette.primary.light,
    //   color: theme.palette.text.dark,
  },
}));

export const FormBuilder = (props) => {
  const classes = useStyles();

  const {
    forms,
    viewSection = () => false,
    getSectionId,
    answer = {},
    statusUpdateData = {},
    secName = "",
    secToShow,
    form_id,
    entry_id,
    isReadyOnly = false,
    parent_form_id,
    isEdit,
    setView,
    onAnswerUpdated = () => false,
    patientDetail = {},
  } = props;

  // console.log("Form BuilderProps", props);

  console.log("IsEdit", isEdit);

  const [formsWithAnswer, setFormsWithAnswer] = React.useState(answer);
  const [isChangesSaved, setIsChangesSaved] = React.useState(false);
  const dispatch = useDispatch();
  const [smartAnswer, setSmartAnswer] = React.useState(true);
  const [errorValidation, setErrorValidation] = React.useState({});
  const userData = useSelector((state) => state?.authSlice?.login?.data);
  const backDrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  let currentButton;
  let currentForm = forms[0];
  let currentIndex = 1;
  let validated;

  React.useEffect(() => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      let answer =
        formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]?.answer ??
        "No";
      setSmartAnswer(answer === "Yes");
    } else {
      setSmartAnswer(false);
    }

    // setView([currentIndex, currentForm]);
    // eslint-disable-next-line
  }, [currentForm, formsWithAnswer]);

  const mobileValidations = (answer, id) => {
    if (answer && answer?.phoneCode?.length > 0) {
      const limit = getMobileLimitBasedOnCC(answer.phoneCode);
      if (limit === answer?.number?.length) {
        return "";
      } else {
        errorValidation[id] = {
          error: true,
          errorMessage: ``,
        };
      }
    } else {
      errorValidation[id] = {
        error: true,
        errorMessage: `Please fill the required field`,
      };
    }
  };

  const locationLevelValidations = (answer, id) => {
    if (answer?.country) {
      return "";
    } else {
      errorValidation[id] = {
        error: true,
        errorMessage: `Please select the country field`,
      };
    }
  };

  const storeErrorValidations = (id, msg) => {
    errorValidation[id] = {
      error: true,
      errorMessage: msg,
    };
  };

  const stringValidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checkStringValidations(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };

  const numericValidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checkNumericValidations(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };

  const dobValidations = (item, val, id) => {
    if (val?.age >= 0 || item[0]?.is_required) {
      checkDobValidations(
        item[0]?.validation,
        val?.age,
        id,
        storeErrorValidations
      );
    }
  };

  const tableValidations = (table_scheme, val, id) => {
    errorValidation[id] = [];

    table_scheme.row.map((l, i) => {
      //get the all index that has validations property
      let findIndexKeys = l.validations && Object.keys(l.validations);

      //get the index that has date validations
      let removeDateValidations = table_scheme.column
        .map((l, j) => {
          if (l.ui_component === "date") {
            return j;
          }
          return undefined;
        })
        .filter((l) => l !== undefined);

      // remove date valiadtions from findIndexKeys because date validations done automatically in UI
      findIndexKeys = findIndexKeys.filter(
        (l, i) => l !== removeDateValidations[i]?.toString()
      );

      if (findIndexKeys.length > 0) {
        findIndexKeys?.map((f) => {
          checkStringValidations(
            l.validations[f],
            val.tableData[i][f],
            id,
            (id, msg) => tableErrorValidations(id, msg, i, f)
          );
          return f;
        });
      }
      return l;
    });

    if (errorValidation[id]?.length === 0) {
      delete errorValidation[id];
    }
  };

  const tableErrorValidations = (id, msg, rowIndex, dataIndex) => {
    errorValidation[id][rowIndex] = [];

    errorValidation[id][rowIndex][dataIndex] = {
      error: true,
      errorMessage: msg,
    };
  };

  const canIableToMoveNextForm = (node) => {
    if (node?.dependent) {
      let valueArr = node?.dependent?.option?.map((_) => _.value) ?? [];

      if (
        valueArr.indexOf(
          formsWithAnswer[node?.dependent?.parent_question_id]?.answer
        ) > -1
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const onPrevBtnClicked = (currentIndex, currentForm) => {
    if (currentIndex !== 1) {
      if (canIableToMoveNextForm(forms[currentIndex - 2])) {
        getSectionId(forms[currentIndex - 2]);
      } else {
        // onNextBtnClicked(currentIndex - 3, forms[currentIndex], false, true);
      }
    }
  };

  const savingJSON = useSelector(
    (state) =>
      state?.renderSlice?.upsertEntry ?? {
        loading: false,
        data: [],
        error: false,
      }
  );

  const canIshowSmartQuestion = (currentForm) => {
    return currentForm?.framed_questions?.[0]?.priority === 0;
  };

  const giveMeNotifyType = (isDraft) => {
    if (isDraft) {
      return 1;
    } else {
      switch (secName) {
        case 0:
          return 2;
        case 1:
          return 3;
        case 2:
          return 4;
        case 4:
          return 5;

        default:
          return 0;
      }
    }
  };

  const checkSectionType = () => {
    if (statusUpdateData?.verifier_status === 3 && secName === 0) {
      return true;
    } else if (statusUpdateData?.verifier_status === 3 && secName === 1) {
      return true;
    } else if (statusUpdateData?.verifier_status === 10 && secName === 1) {
      return true;
    } else if (statusUpdateData?.registration_status === 8 && secName === 1) {
      return true;
    } else if (statusUpdateData?.registration_status === 10 && secName === 2) {
      return true;
    } else if (statusUpdateData?.registration_status === 8 && secName === 2) {
      return true;
    } else if (statusUpdateData?.registration_status === 6 && secName === 3) {
      return true;
    } else if (statusUpdateData?.investigator_status === 6 && secName === 3) {
      return true;
    } else if (currentForm?.section_type && currentForm?.section_type >= 0) {
      if (currentForm?.section_type === secName) {
        return false;
      } else {
        return true;
      }
    } else if (!currentForm?.section_type && secName === 0) {
      if (
        statusUpdateData?.notifier_status === 1 &&
        statusUpdateData?.verifier_status === 10
      ) {
        return false;
      } else if (statusUpdateData?.notifier_status === 1) {
        return true;
      } else {
        return false;
      }
    } else if (currentForm?.is_investigator_section && secName === 3) {
      return false;
    } else {
      return true;
    }
  };

  const isSmartAnswerCheckNeed = (currentForm) => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      return smartAnswer;
    } else {
      return true;
    }
  };

  const giveFormLayoutType = () => {
    if (currentForm?.formLayoutType === "1 Column Layout") {
      return 12;
    } else if (currentForm?.formLayoutType === "2 Column Layout") {
      return 6;
    } else {
      return 4;
    }
  };

  const giveMeSM = (ui_component) => {
    if (
      ui_component === "custom_table" ||
      ui_component === "custom_table_2" ||
      ui_component === "location_level"
    ) {
      return 12;
    } else {
      return 6;
    }
  };

  const giveMeMD = (ui_component) => {
    if (
      ui_component === "custom_table" ||
      ui_component === "custom_table_2" ||
      ui_component === "location_level"
    ) {
      return 12;
    } else {
      return giveFormLayoutType();
    }
  };

  const getButtonName = () => {
    debugger;
    if (currentIndex === forms.length) {
      if (savingJSON.loading) {
        if (isEdit) {
          return "Updating...";
        } else {
          return "Submitting...";
        }
      } else {
        if (isEdit) {
          if (isReadyOnly) {
            if (currentForm.is_investigator_section) {
              return "Investigated";
            } else {
              return (
                <Typography variant="body1" className={classes.nxtBtn}>
                  Next <ChevronRightIcon fontSize="small" />
                </Typography>
              );
            }
          } else {
            return "Submit";
          }
        } else {
          return "Submit";
        }
      }
    } else {
      if (isReadyOnly) {
        if (currentForm.is_investigator_section) {
          return "Next";
        } else {
          return (
            <Typography variant="body1" className={classes.nxtBtn}>
              Next <ChevronRightIcon fontSize="small" />
            </Typography>
          );
        }
      } else {
        return "Next";
      }
    }
  };

  const onChange = (index, data, value) => {
    debugger;

    let newForms = formsWithAnswer;

    newForms[data?.id] = {
      id: data?.id,
      answer: value,
    };

    setFormsWithAnswer({
      ...newForms,
    });
    onAnswerUpdated(newForms);
  };

  const checkRequired = (form, answer) => {
    const requiredTrueids = [];
    const answerdetails = answer;
    const availabletrues = [];

    if (requiredTrueids.length > 0 && Object.keys(answer).length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the required fields !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    }

    let isSmartSkip = "";

    const FramedQuestions = form.framed_questions;

    const checkSmartQuesionAvailable = FramedQuestions.map((item) => {
      if (item.is_required === true && item.ui_component === "popup_switch") {
        return true;
      } else {
        return false;
      }
    });
    // eslint-disable-next-line
    const mapFramedQuestion = FramedQuestions.map((item) => {
      if (
        (item.is_required === true ||
          (item?.validation && Object.keys(item?.validation)?.length > 0)) &&
        item.ui_component !== "label"
      ) {
        if (
          item.ui_component === "input_date_picker" &&
          item.is_required === true
        ) {
          requiredTrueids.push(item);
        } else if (item.ui_component !== "input_date_picker") {
          requiredTrueids.push(item);
        }
      }
    });

    const requiredId = requiredTrueids.map((item) => {
      return item.id;
    });

    //empty the errorValidation object to check
    for (var item in errorValidation) delete errorValidation[item];

    // eslint-disable-next-line
    const checking = requiredId.map((item) => {
      if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "input_number"
      ) {
        numericValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "input_text" ||
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "input_textarea"
      ) {
        stringValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "mobile_no"
      ) {
        mobileValidations(answerdetails[item]?.answer, item);
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "location_level"
      ) {
        locationLevelValidations(answerdetails[item]?.answer, item);
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "date_of_birth"
      ) {
        dobValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "custom_table" ||
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "custom_table_2"
      ) {
        tableValidations(
          requiredTrueids?.filter((l) => l.id === item)[0]?.table_scheme,
          answerdetails[item]?.answer,
          item
        );
      } else {
        if (
          answerdetails[item]?.answer?.length > 0 ||
          answerdetails[item]?.answer?.value?.length > 0 ||
          answerdetails[item]?.answer?.tableData?.length > 0 ||
          answerdetails[item]?.answer?.address?.length > 0
        ) {
          availabletrues.push(answerdetails[item]);
        } else {
          errorValidation[item] = {
            error: true,
            errorMessage: `Please fill the required field`,
          };
        }
      }
      // validation for smart skip
      if (
        Object.values(checkSmartQuesionAvailable).indexOf(true) > -1 &&
        answerdetails[item]?.answer === "No"
      ) {
        // let requiredTrueidsLength = requiredTrueids.length;
        // availabletrues.length = requiredTrueidsLength;

        //empty the errorValidation object to check
        for (var items in errorValidation) delete errorValidation[items];

        isSmartSkip = true;
      }

      // }
    });

    setErrorValidation(errorValidation);

    if (checkSmartQuesionAvailable && isSmartSkip) {
      return true;
    } else {
      if (errorValidation && Object.keys(errorValidation)?.length === 0) {
        return true;
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Please fill the required fields !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return false;
      }
    }
  };

  // React.useEffect(() => {
  //     // eslint-disable-next-line
  //     currentButton = getButtonName();

  //     if (Object?.keys(formsWithAnswer)?.length > 4) {
  //         setIsChangesSaved(false);
  //     }

  //     // eslint-disable-next-line
  // }, [getButtonName])

  const onNextBtnClicked = async (
    currentIndex,
    currentForm,
    isDraft = false,
    isSkip = false
  ) => {
    debugger;
    //Checking wheather the section is skipable or not
    if (isSkip) {
      validated = true;
      setErrorValidation({});
    } else {
      validated = checkSectionType()
        ? true
        : checkRequired(currentForm, formsWithAnswer);
    }

    if (validated) {
      const dataForViewSection = [currentIndex, currentForm];
      setView(dataForViewSection);

      if (currentIndex === forms.length || isDraft) {
        const roleDataSecurity = giveMeRoleDataSecurity(userData);

        backDrop.setBackDrop({
          ...backDrop,
          open: true,
          message: "Saving your entry...",
        });

        let params = {
          form_id: parent_form_id,
          data: formsWithAnswer,
          entry_id: entry_id,
          isEdit: isEdit,
          notifier_status: isDraft ? 9 : 1,
          notifyType: isDraft,
          roles: roleDataSecurity.myRole,
          patient_id: patientDetail?.patient_id,
          patientDetail: patientDetail?.createPatientData,
          createPatient: patientDetail?.createPatient,
          secName,
        };

        if (isEdit) {
          params.entry_id = entry_id;
          params.isEdit = isEdit;
          params.verifier_status = secName === 1 ? 3 : null;
          params.registration_status = secName === 2 ? 8 : null;
          params.investigator_status = secName === 3 ? 6 : null;
        } else {
          params.location = {
            country: roleDataSecurity.country,
            level1: roleDataSecurity.level1,
            level2: roleDataSecurity.level2,
            level3: roleDataSecurity.level3,
            level4: roleDataSecurity.level4,
            level5: roleDataSecurity.level5,
            level6: roleDataSecurity.level6,
            level7: roleDataSecurity.level7,
            level8: roleDataSecurity.level8,
            level9: roleDataSecurity.level9,
            level10: roleDataSecurity.level10,
            level11: roleDataSecurity.level11,
            level12: roleDataSecurity.level12,
          };
        }

        Promise.resolve(dispatch(actions.UPSERT_ENTRY(params))).then((res) => {
          backDrop.setBackDrop({
            ...backDrop,
            open: false,
          });

          if (!res.payload.error) {
            if (!isDraft && !isEdit) {
              setIsChangesSaved(true);
            }

            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: `Notification has been ${
                isDraft ? "saved as draft" : isEdit ? "updated" : "submitted"
              } Successfully!!!`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });

            props?.setIsSubmitted(false);
            localStorage.setItem("entry_id", entry_id);

            if (!isDraft) {
              getSectionId(forms[0]);
            }

            // if (isDraft) {
            //   setAllowDraft(true);
            //   getSectionId(forms[0]);
            //   history.push({
            //     pathname: Routes.notifications,
            //     search: `?id=${parent_form_id}&tab=1`,
            //   });
            // }

            // if (!isDraft && !isEdit) {
            //   history.push({
            //     pathname: Routes.notifications,
            //     search: `?id=${parent_form_id}&tab=0`,
            //   });
            // }

            // if (isEdit) {
            //   navigateToListingPage(parent_form_id);
            // } else {
            //   if (!isDraft) {
            //     setHistoryOfViewedForms([]);
            //     setFormsWithAnswer({});
            //     setView([]);
            //     restForm();
            //   };
            // }
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something went wrong! Try Again",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        });
      } else {
        if (canIableToMoveNextForm(forms[currentIndex])) {
          getSectionId(forms[currentIndex]);
        } else {
          onNextBtnClicked(currentIndex + 1, forms[currentIndex], false);
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <LinearProgressWithLabel
            total={forms?.length ?? 1}
            progress={currentIndex}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.header}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              id="FormTitle"
              variant="body1"
              color="textPrimary"
              className={classes.formTitle}
            >
              <Tooltip title="View Section" placement="top">
                <IconButton
                  className={classes.menuIcon}
                  id="ViewSection"
                  onClick={() => {
                    viewSection();
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
              {currentForm?.section_name}
            </Typography>
          </Grid>
          {
            <Grid item style={{ paddingRight: 8 }}>
              {currentIndex !== 1 && (
                <Button
                  id="Prev"
                  style={{ marginLeft: 8, marginRight: 8 }}
                  size="small"
                  variant="text"
                  className={classes.savebtn}
                  disabled={savingJSON.loading}
                  onClick={() => onPrevBtnClicked(currentIndex, currentForm)}
                >
                  {"Prev"}
                </Button>
              )}

              {
                <Button
                  id="objectbutton"
                  size="small"
                  variant="text"
                  className={classes.savebtn}
                  disabled={
                    savingJSON.loading
                      ? savingJSON.loading
                      : currentIndex === forms.length && checkSectionType()
                      ? true
                      : false
                  }
                  onClick={() => onNextBtnClicked(currentIndex, currentForm)} // onNextBtnClicked(currentIndex, currentForm)
                >
                  {getButtonName()}
                </Button>
              }
            </Grid>
          }
        </Grid>

        {/* <Grid>
                    <Prompt
                        when={
                            isChangesSaved === false &&
                            currentButton !== "Submit" &&
                            !allowDraft &&
                            (currentButton === "Submit" ||
                                (!checkSectionType() && !isReadyOnly))
                        }
                        message={`Are you sure you want to Leave the Form ? `}
                    />
                </Grid> */}

        {canIshowSmartQuestion(currentForm) && (
          <Grid item xs={12} className={classes.uiComponentWrapper}>
            <ComponentToRender
              data={currentForm?.framed_questions?.[0]}
              value={
                formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]
                  ?.answer ?? ""
              }
              index={0}
              //onChange={onChange}
            />
          </Grid>
        )}

        <Divider variant="fullWidth" />
        <Grid container>
          {isSmartAnswerCheckNeed(currentForm) &&
            currentForm?.framed_questions &&
            currentForm?.framed_questions
              ?.filter((frQ) => frQ?.priority !== 0)
              .map((frQ, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={giveMeSM(frQ?.ui_component)}
                    md={giveMeMD(frQ?.ui_component)}
                    key={i}
                    className={classes.uiComponentWrapper}
                    id={"Grid" + i}
                  >
                    <ComponentToRender
                      data={frQ}
                      isrequired={frQ.is_required}
                      value={formsWithAnswer?.[frQ.id]?.answer ?? ""}
                      index={i}
                      onChange={onChange}
                      // isReadyOnly={checkSectionType()}
                      //   errorValidation={errorValidation[frQ.id]}
                      //   disabled={canIVisibleHere(frQ)}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </Grid>
    </div>
  );
};
