import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import ManageLevel from "./ManageLevel";
import { actions } from "hcc-binder";
import { connect, useDispatch } from "react-redux";

const styles = (theme) => ({
  root: {
    flex: 1,
  },
});
const LocationManageParent = (props) => {
  const [datass, setdatas] = useState([]);
  const [parorg, setparentorg] = useState([]);
  const dispatch = useDispatch();

  useEffect(async () => {
    //getparentorg()
    let value = await dispatch(
      actions.ORGANIZATION_TREE_READ({
        client_id: props?.client_id,
      })
    );
    setparentorg(value);
  }, []);
  //   const getparentorg = async () => {
  //     let value = await dispatch(
  //         actions.GET_SINGLE_CLIENT({
  //             client_id: props?.client_id
  //         })
  //       );
  //       setparentorg(value)
  //       //console.log("value" ,value)

  //   }

  const {
    classes,
    getAllCountries,
    label,
    isReadonly,
    isrequired,
    handleChange,
    value,
    errorValidation,
    disabled,
    id,
    primeLocation,
    showAs,
    mappedLocation,
    isAdmin,
  } = props;

  //console.log("parorg" , parorg)

  return (
    <div className={classes.root}>
      {!parorg?.loading && !parorg?.error && (
        <ManageLevel
          id={id}
          primeLocation={primeLocation}
          countryData={parorg}
          label={label}
          value={value}
          handleChange={handleChange}
          isReadonly={isReadonly}
          isrequired={isrequired}
          errorValidation={errorValidation}
          disabled={disabled}
          showAs={showAs}
          mappedLocation={mappedLocation}
          isAdmin={isAdmin}
          client_id={props?.client_id}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(LocationManageParent);
