import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
// import { apis } from "hcc-binder";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import Select from "../select";
import { actions, apis } from "hcc-binder";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps } from "../../utils/constants";
import SearchIcon from "@material-ui/icons/Search";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import LastPageOutlinedIcon from "@material-ui/icons/LastPageOutlined";
const labelfinder = (data) => {
  // debugger;
  let arraylist = Object.keys(data);
  let label;
  const result = arraylist.forEach((v) => {
    if (v === "Content_Name") {
      label = "Content_Name";
    } else if (v === "Service_Name") {
      label = "Service_Name";
    }
  });
  console.log({ result });
  return label;
};

const styles = makeStyles((theme) => ({
  editButton: {
    marginLeft: "10px",
    backgroundColor: "#0071F2",
    borderRadius: "0px",
    color: "#fff",
  },
  drawerHead: {
    display: "flex",
    padding: "25px",
    borderBottom: "1px solid #eee",
  },
  drawerSubHead: {
    fontFamily: "poppin",
    color: "#6F6F6F",
    fontSize: "12px",
    paddingBottom: "8px",
  },
  addDrawerBtn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    fontFamily: "poppin",
    textTransform: "none",
    padding: "8px 60px",
    marginTop: "20px",
  },
  textTotalArea: {
    padding: "20px 30px",
  },
  headerMain: {
    //fontFamily: "poppinsemibold",
  },
  btnPosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  helperText: {
    color: "#ff0000",
  },
  root: {
    borderRadius: "0px",
    width: "100%",
  },
  firstSec: {
    padding: "15px 45px",
    borderBottom: "3px solid #9b9999",
  },
  firstSecRepository: {
    padding: "15px 45px",
  },
  btn: {
    //backgroundColor: "#0071F2",
    color: "#fff",
    padding: "6px 16px",
    // fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      height: "15px",
    },
  },
  rolesDisplay: {
    width: "auto",
    border: "1px solid #DCDCDC",
    display: "flex",
    justifyContent: "center",
    padding: "10px 30px",
    marginBottom: "10px",
    borderRadius: "8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  lowerDisplay: {
    width: "99%",
    border: "1px solid #DCDCDC",
    display: "flex",
    padding: "14px 16px",
    borderRadius: "8px",
    alignItems: "center",
  },
  secondSec: {
    padding: "20px 45px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      background: "#0071F2",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #f4f4f5",
    },
  },
  tabEdit: {
    textTransform: "none",
  },
  Section2: {
    height: `calc(100vh - 452px)`,
    overflow: "auto",
    paddingBottom: "25px",
  },
  Section2NoMain: {
    height: `calc(100vh - 192px)`,
    overflow: "auto",
    paddingBottom: "25px",
  },

  mainAreaRepository: {
    height: `calc(100vh - 216px)`,
    overflow: "auto",
    margin: "10px 0px",
    paddingBottom: "20px",
  },
  mainArea: {
    height: "250px",
    overflow: "auto",
    marginTop: "10px",
  },
  mainAreaNoData: {
    height: "0px",
  },
  selectedCell: {
    cursor: "pointer",
    border: "1px solid #0071F2",
    width: "99%",
    display: "flex",
    padding: "14px 30px",
    marginBottom: "10px",
    borderRadius: "8px",
  },
  numbersEdit: {
    marginTop: "4px",
    //fontFamily: "poppinsemibold",
    padding: "10px 20px 6px",
    textAlign: "center",
  },
  paperEdit: {
    borderRadius: "0px",
  },
  innerText: {
    fontSize: "14px",
    // width: "100%",
    //fontFamily: "poppinsemibold",
  },
  innerText1: {
    fontSize: "14px !important",
    fontWeight: "450",
  },

  descriptionTab: {
    display: "flex",
    justifyContent: "center",
    border: "1px solid #DCDCDC",
    padding: "9px 30px 9px 30px",
    borderRadius: "8px",
    backgroundColor: "#f6f6f6",
  },

  table: {
    minWidth: 650,
  },
  tableHeader: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontFamily: "poppin",
    fontSize: "12px",
    color: "gray",
    backgroundColor: "#f9f9f9",
  },
  tableBody: {
    width: "120px",
    padding: "12px",
    //fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  tableContainer: {
    borderRadius: "8px",
    marginTop: "20px",
    bomdhadow: "none",
    border: "1px solid #E0E0E0",
    height: `calc(100vh - 218px)`,
  },
  repoLeftSide: {
    height: `calc(100vh - 143px)`,
    borderRadius: "10px",
    backgroundColor: "#fff",
    border: "1px solid #DCDCDC",
  },
  active: {
    backgroundColor: "#E0EBF9 !important",
    color: "#0071F2 !important",
  },
  groupedBtnAlign: {
    padding: "5px",
    margin: "auto",
    display: "flex",
    justifyContent: "flex-end",
  },

  rolesChipRoot: {
    borderRadius: 4,
    height: 26,
    color: theme.palette.text.primary,
    //fontFamily: "poppinsemibold",
    background: "#F0F0F0",
  },
  autocompleteTag: {
    borderRadius: 8,
    height: 26,
    color: theme.palette.text.primary,
    //fontFamily: "poppinsemibold",
    background: "#F0F0F0",
    "& .MuiChip-label": {
      paddingRight: 24,
    },
  },

  boxFirstDiv: {
    marginRight: "2%",
    marginBottom: "2%",
  },
  detailBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "1%",
    color: "#6f6f6f",
  },
  iconStyle: {
    fontSize: 18,
    color: "#0071F2",
    marginRight: 6,
  },
  detailData: {
    marginLeft: "18px",
    textAlign: "left",
    //fontFamily: "poppinsemibold",
    fontSize: "0.9rem",
  },
  displaySectionTitle: {
    //fontFamily: "poppinsemibold",
    padding: "10px 6px",
    display: "inline-block",
  },
  nodatafound: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  treeparent: {
    fontSize: "10px",
  },
  treechild: {
    "& .MuiTreeItem-label": {
      fontSize: "14px",
    },
    padding: "3px",
  },
  textarea: {
    minHeight: "40vh",
    maxHeight: "50vh",
    minWidth: "33vw",
    [theme.breakpoints.down("sm")]: {
      minWidth: "56.5vw",
    },
  },
  gridpadding: {
    paddingRight: "3px",
  },
}));

const httpmethod = [
  { label: "POST", value: "post" },
  { label: "GET", value: "get" },
];
const valefinderfunction = (value) => {
  const result = httpmethod.filter((v) => {
    return v.value === value;
  });
  return result?.[0];
};

const jsonvalidation = (data) => {
  try {
    JSON.parse(data);
    return false;
  } catch (err) {
    return true;
  }
};
const editcontentypeobjcontrcutor = (data = [], id) => {
  // debugger;
  let filter = data?.filter((v) => {
    return v?.Content_ID === id;
  });
  let result = valuelabelfinder(filter);
  return result?.[0];
};
const valuelabelfinder = (data) => {
  try {
    const result = data.map((v) => {
      let obj = {
        label: v.Content_Name,
        value: v.Content_ID,
        _id: v?._id,
      };
      return obj;
    });
    console.log("name", result);
    return result;
  } catch (err) {
    return [];
  }
};
const datacontructor = (contentdata = [], servicedata = []) => {
  let finaldata = {
    children: [],
  };
  const result = contentdata.map((v) => {
    let contentobj = {
      ...v,
      children: [],
    };
    let result = servicedata.filter((e) => e.Content_ID === v.Content_ID);
    contentobj.children = [...contentobj.children, ...result];
    console.log(contentobj);
    finaldata.children.push(contentobj);
  });
  console.log(finaldata.children);
  return finaldata.children;
};
function Service(props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [currentcarddata, setCurrentcarddata] = useState({});
  const [usermsg, setUsermsg] = useState(
    "Please add service or click contents in the tree to view service list"
  );
  const alertContext = React.useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const selector = useSelector((state) => ({
    getsinglecontent: state?.providerSlice?.getsinglecontent?.data?.result,
    getsingleservice: state?.providerSlice?.getsingleservice?.data?.result,
    getallcontentandservice:
      state?.providerSlice?.getallcontentandservice?.data?.result,
  }));

  const selectorforloading = useSelector((state) => ({
    getsinglecontent: state?.providerSlice?.getsinglecontent,
    getsingleservice: state?.providerSlice?.getsingleservice,
  }));
  const [expanded, setExpanded] = useState(["1"]);
  const [state, setState] = useState({
    apiname: "",
    httpmethod: {},
    url: "",
    requestschema: "",
    responseschema: "",
    contentname: {},
    isEdit: false,
    endpoint: "",
    error: {
      totalerror: false,
      apiname: false,
      httpmethod: false,
      url: false,
      requestschema: false,
      responseschema: false,
      endpoint: false,
      contentname: false,
    },
    message: {
      apiname: "",
      httpmethod: "",
      url: "",
      requestschema: "",
      responseschema: "",
      endpoint: "",
      contentname: "",
    },
  });
  React.useEffect(() => {
    dispatch(actions.getsinglecontent(props?.state?.Provider_Id));
    // setCurrentcarddata(selector.getsinglecontent?.[0] ?? {});
    dispatch(actions.getallcontentandservice(props?.state?.Provider_Id));
    // selector.getsinglecontent?.[0] &&
    //   handlecheckservice(selector.getsinglecontent?.[0] ?? {});
  }, []);

  console.log(selector, actions, state);
  React.useEffect(() => {
    setFilteredData(selector.getsinglecontent ?? []);
    // datacontructor(selector.getsinglecontent, selector.getsingleservice);
  }, [selector.getsinglecontent]);
  // React.useEffect(() => {
  //   setState({
  //     apiname: selector.getsingleservice?.[0]?.Service_Name ?? "",
  //     endpoint: selector.getsingleservice?.[0]?.Endpoint ?? "",
  //     httpmethod: valefinderfunction(
  //       selector.getsingleservice?.[0]?.Method
  //     ) ?? [{}],
  //     url: selector.getsingleservice?.[0]?.Service_URL ?? "",
  //     requestschema:
  //       JSON.stringify(
  //         selector.getsingleservice?.[0]?.Request_Schema,
  //         null,
  //         1
  //       ) ?? "",
  //     responseschema:
  //       JSON.stringify(
  //         selector.getsingleservice?.[0]?.Response_Schema,
  //         null,
  //         1
  //       ) ?? "",
  //     isEdit: selector.getsingleservice?.length !== 0 ? true : false,
  //   });
  // }, [selector.getsingleservice]);

  const renderTree = (nodes, name) => {
    debugger;
    let label = nodes?.[labelfinder(nodes)] ?? props?.state?.["Providers Name"];
    let objsvalue = labelfinder(nodes);
    console.log("labelfinder", label, objsvalue === "Service_Name");
    try {
      return Array.isArray(nodes?.children) && nodes?.children?.length === 0 ? (
        <TreeItem
          className={classes.treechild}
          key={nodes?._id ?? 1}
          nodeId={nodes?._id ?? 1}
          label={label}
          onClick={() => {
            objsvalue === "Service_Name"
              ? handletreelistclick(nodes)
              : setCurrentcarddata({});
          }}
        >
          {" "}
          {Array.isArray(nodes?.children)
            ? nodes?.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      ) : (
        <TreeItem
          className={classes.treechild}
          key={nodes?._id ?? 1}
          nodeId={nodes?._id ?? 1}
          label={label}
          onClick={() => {
            objsvalue === "Service_Name" && handletreelistclick(nodes);
          }}
        >
          {Array.isArray(nodes?.children)
            ? nodes?.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      );
    } catch (err) {
      return <></>;
    }
  };

  const handletreelistclick = (value) => {
    // debugger
    setState({
      apiname: value?.Service_Name ?? "",
      endpoint: value?.Endpoint ?? "",
      httpmethod: valefinderfunction(value?.Method),
      url: value?.Service_URL ?? "",
      requestschema: JSON.stringify(value?.Request_Schema, null, 1) ?? "",
      responseschema: JSON.stringify(value?.Response_Schema, null, 1) ?? "",
      contentname: editcontentypeobjcontrcutor(
        selector.getsinglecontent,
        value?.Content_ID
      ),
      isEdit: value?.Service_Name ? true : false,
    });
    setCurrentcarddata(value);
  };

  const treelist = (data, labelname) => {
    try {
      return data.map((v, i) => {
        console.log(v?.children?.length);
        return Array.isArray(v?.children) ? (
          v?.children.length === 0 ? (
            <TreeItem
              key={i}
              className={classes.treechild}
              nodeId={i}
              label={v?.["Service_Name"]}
              onClick={() => {
                handletreelistclick(v);
              }}
            />
          ) : (
            <TreeItem
              key={i}
              nodeId={i}
              label={v?.["Content_Name"]}
              className={classes.treechild}
            >
              {treelist(v.children)}
            </TreeItem>
          )
        ) : (
          <TreeItem
            key={i}
            className={classes.treechild}
            nodeId={i}
            label={v?.["Service_Name"]}
            onClick={() => {
              handletreelistclick(v);
            }}
          />
        );
      });
    } catch (err) {
      return <></>;
    }
  };

  const handleChange = (key, value, istextarea, fullset) => {
    try {
      if (istextarea) {
        try {
          // let beatifiedvalue = JSON.stringify(value, null, 1);
          //setState({ ...state, [key]: beatifiedvalue });
          JSON.parse(value);
          setState({
            ...state,
            [key]: value,
            error: { ...state.error, [key]: false },
            message: { ...state.message, [key]: "" },
          });
        } catch (err) {
          setState({
            ...state,
            [key]: value,
            error: { ...state.error, [key]: true, totalerror: true },
            message: { ...state.message, [key]: "JSON is not valid" },
          });
        }
      } else {
        if (typeof value === "string") {
          setState({ ...state, [key]: value });
        } else if (typeof value === "object") {
          if (Array.isArray(value)) {
            if (fullset) {
              setState({
                ...state,
                [key]: [...value],
              });
            } else {
              setState({
                ...state,
                [key]: [...state?.[key], ...value],
              });
            }
          } else {
            setState({
              ...state,
              [key]: value,
            });
          }
        }
      }
    } catch (err) {
      alert("error on object");
      console.log({ err });
    }
  };
  console.log({ state });
  const searchfunc = (e) => {
    let value = e.target.value;
    if (value) {
      let filter = selector.getsinglecontent?.filter((v) => {
        return (
          v?.Content_Name?.toLowerCase()?.includes(value.toLowerCase()) ||
          v?.Content_Type?.label?.toLowerCase()?.includes(value.toLowerCase())
        );
      });
      setFilteredData(filter);
    } else {
      setFilteredData(selector.getsinglecontent);
    }
  };
  const errorvalidation = () => {
    debugger;
    let errorobj = {};
    let errormsg = {};
    let keyvalues = [
      "contentname",
      "httpmethod",
      "apiname",
      "url",
      "requestschema",
      "responseschema",
      "endpoint",
    ];
    keyvalues.forEach((v) => {
      debugger;
      if (typeof state[v] === "string") {
        errorobj[v] = state[v]?.length > 0 ? false : true;
        errormsg[v] = state[v]?.length > 0 ? " " : "This field is required";

        if (v === "requestschema" || v === "responseschema") {
          errorobj[v] = jsonvalidation(state[v]) ? true : false;
          errormsg[v] = jsonvalidation(state[v]) ? "JSON is not valid" : " ";
        }
      } else if (typeof state[v] === "object") {
        errorobj[v] = Object.keys(state[v])?.length > 0 ? false : true;
        errormsg[v] =
          Object.keys(state[v])?.length > 0 ? " " : "This field is required";
        console.log("error", Object.keys(state[v]));
      }
    });
    const totaltrue = keyvalues.findIndex((v) => errorobj[v]);
    errorobj.totaltrue = totaltrue < 0 ? false : true;

    return { errorobj, errormsg };
  };
  const handlesaveservice = () => {
    const errorvalue = errorvalidation();
    if (errorvalue?.errorobj?.totaltrue) {
      setState({
        ...state,
        error: { ...state.error, ...errorvalue.errorobj },
        message: { ...state.message, ...errorvalue.errormsg },
      });
      return;
    } else {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "adding service",
      });
      let data = {
        Service_ID: uuidV4(),
        Content_ID: state?.contentname?.value,
        Provider_Id: props?.state?.Provider_Id,
        Service_URL: state.url,
        Method: state.httpmethod.value,
        Request_Schema: JSON.parse(state.requestschema),
        Response_Schema: JSON.parse(state.responseschema),
        Api_Name: state.apiname,
        Endpoint: state.endpoint,
        Service_Name: state.apiname,
        contentid: state?.contentname?._id,
      };
      let params = {
        doc: data,
        filter: { filter: { Service_ID: `${currentcarddata?.Service_ID}` } },
      };

      Promise.resolve(apis.UpsertandUpdateforservice(params))
        .then((res) => {
          console.log(res);
          alertContext.setSnack({
            ...alertContext,
            open: true,
            severity: AlertProps.severity.success,
            msg: state.isEdit
              ? "service updated successfully"
              : "service inserted successfully",
            horizontal: AlertProps.horizontal.right,
            vertical: AlertProps.vertical.top,
          });
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          dispatch(actions.getallcontentandservice(props?.state?.Provider_Id));

          setState({
            apiname: "",
            httpmethod: {},
            url: "",
            requestschema: "",
            responseschema: "",
            Endpoint: "",
            contentname: {},
          });
          setCurrentcarddata({});
        })
        .catch((err) => console.log(err));
    }
  };
  console.log({ currentcarddata });
  const handlecheckservice = async (value) => {
    setCurrentcarddata(value);
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "getting service",
    });
    await Promise.resolve(dispatch(actions.getsingleservice(value.Content_ID)))
      .then((res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Something went wrong",
          horizontal: AlertProps.horizontal.right,
          vertical: AlertProps.vertical.top,
        });
      });
    console.log({ selector });
  };
  const handledelete = () => {
    let data = {
      activestatus: false,
    };
    let params = {
      doc: data,
      filter: { filter: { Service_ID: `${currentcarddata.Service_ID}` } },
    };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "deleting service",
    });
    Promise.resolve(apis.UpsertandUpdateforservice(params))
      .then((res) => {
        console.log(res);
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Service deleted successfully",
          horizontal: AlertProps.horizontal.right,
          vertical: AlertProps.vertical.top,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        dispatch(actions.getallcontentandservice(props?.state?.Provider_Id));

        setState({
          apiname: "",
          httpmethod: {},
          url: "",
          requestschema: "",
          responseschema: "",
          Endpoint: "",
          contentname: {},
        });
        setCurrentcarddata({});
      })
      .catch((err) => console.log(err));
  };
  console.log({ selectorforloading });

  const handletoogle = (e, node) => {
    setExpanded(node);
    console.log("node", e.nativeEvent.srcElement.outerText);
    let msg = (
      <p>
        Please select the node with this{" "}
        <strong>
          {" "}
          <LastPageOutlinedIcon fontSize="medium" />{" "}
        </strong>
        icon in order to view or edit the service
      </p>
    );
    console.log("msg", e?.nativeEvent?.srcElement?.outerText);
    // setUsermsg(
    //   `Please select the ${e?.nativeEvent?.srcElement?.outerText}(content's) service in order to view or edit`
    // );
    setUsermsg(msg);
  };
  const handleselect = (event, nodeid = "") => {
    debugger;
    let iscontent = nodeid?.startsWith("Content");
    let msg = "";
    if (iscontent) {
      const filter = selector.getallcontentandservice.filter((v) => {
        return v?._id === nodeid;
      });
      filter?.[0].children.length === 0
        ? (msg = "There is no service to view.Please add the service")
        : (msg = (
            <p>
              Please select the node with this{" "}
              <LastPageOutlinedIcon fontSize="medium" /> icon in order to view
              or edit the service
            </p>
          ));
    }
    setUsermsg(msg);
  };
  return (
    <Grid container style={{ padding: "10px" }} spacing={1}>
      <Grid item md={3} sm={3} xs={3}>
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #DCDCDC",
            borderRadius: "10px 10px 0px 0px",
            padding: "10px",
          }}
        >
          <div>
            {/* ------------------------------Title---------------------- */}
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  marginTop: "4px",
                  fontWeight: "500",
                  fontSize: "1 rem",
                }}
              >
                Manage Service
              </Typography>
              <div style={{ flexGrow: 1 }}></div>
              <Button
                className={classes?.btn}
                color="primary"
                variant="contained"
                contained
                onClick={() => {
                  setCurrentcarddata({ add: true });
                  setState({
                    apiname: "",
                    httpmethod: {},
                    url: "",
                    requestschema: "",
                    responseschema: "",
                    endpoint: "",
                    contentname: {},
                  });
                }} // setting sample data for view the form
                size="small"
              >
                + Add
              </Button>
            </div>
            {/* -----------------------------Search---------------------------- */}
            <div>
              {/* <TextField
                id="outlined-search"
                placeholder="Search field"
                onChange={searchfunc}
                size="small"
                style={{
                  width: "100%",
                }}
                type="search"
                variant="outlined"
                // fullWidth
                InputProps={{
                  style: {
                    // fontFamily: "poppin",
                    fontSize: "12px",
                    background: "#f6f6f6",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: 12 },
                }}
              /> */}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #DCDCDC",
            borderRadius: "0px 0px 10px 10px",
            padding: "10px ",
          }}
        >
          <div>
            <div
              className={classes?.mainArea}
              style={{
                height: `calc(100vh - 180px)`,
              }}
            >
              {selectorforloading.getsinglecontent?.loading ? (
                <div style={{ textAlign: "center", paddingTop: "90px" }}>
                  <CircularProgress />
                </div>
              ) : filteredData?.length > 0 ? (
                // <TreeView
                //   className={classes.treeparent}
                //   defaultCollapseIcon={<ExpandMoreIcon />}
                //   defaultExpandIcon={<ChevronRightIcon />}
                //   defaultExpanded={["root"]}
                // >
                //   {treelist(selector.getallcontentandservice)}
                // </TreeView>
                <TreeView
                  className={classes.root}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  // defaultExpanded={["root"]}
                  defaultExpandIcon={<ChevronRightIcon />}
                  defaultEndIcon={<LastPageOutlinedIcon fontSize="small" />}
                  onNodeToggle={(event, nodeid) => handletoogle(event, nodeid)}
                  onNodeSelect={(event, nodeid) => handleselect(event, nodeid)}
                  expanded={expanded}
                >
                  {renderTree(
                    {
                      name: "service",
                      _id: "1",
                      children: selector.getallcontentandservice,
                    },
                    "Content_Name"
                  )}
                </TreeView>
              ) : (
                <div style={{ width: "100%" }}>
                  <Typography
                    className={classes?.innerText}
                    align="center"
                    alignItems="center"
                  >
                    sry data not found !
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </Grid>
      {Object.keys(currentcarddata).length > 0 ? (
        <Grid
          item
          container
          md={9}
          sm={9}
          xs={9}
          spacing={0}
          alignItems={"flex-start"}
          alignContent="flex-start"
        >
          <Grid
            container
            item
            justifyContent="flex-start"
            alignItems="flex-start"
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={0}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid item md={3} sm={6} xs={6} className={classes.gridpadding}>
                <Select
                  name="Content Name"
                  error={state?.error?.contentname}
                  helperText={state?.message?.contentname ?? " "}
                  label={"Content Name"}
                  id="contentname"
                  handleselect={(v) =>
                    handleChange("contentname", Boolean(v) ? v : {}, "")
                  }
                  value={state.contentname ? [state.contentname] : []}
                  option={valuelabelfinder(selector.getsinglecontent)}
                  disabled={state.isEdit}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={6} className={classes.gridpadding}>
                <Select
                  name="Http Method"
                  error={state?.error?.httpmethod}
                  helperText={state?.message?.httpmethod ?? " "}
                  label={"Http Method"}
                  id="httpmethod"
                  handleselect={(v) =>
                    handleChange("httpmethod", Boolean(v) ? v : {}, "")
                  }
                  value={state.httpmethod ? [state.httpmethod] : []}
                  option={httpmethod}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12} className={classes.gridpadding}>
                <TextField
                  name="Service name"
                  fullWidth
                  label="Api Name"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  value={state?.apiname}
                  onChange={(e) => handleChange("apiname", e.target.value)}
                  error={state?.error?.apiname}
                  helperText={state?.message?.apiname ?? " "}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              md={12}
              spacing={0}
            >
              <Grid item md={9} sm={6} xs={12} className={classes.gridpadding}>
                <TextField
                  name="Url"
                  fullWidth
                  label="URL"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  value={state?.url}
                  onChange={(e) => handleChange("url", e.target.value)}
                  error={state?.error?.url}
                  helperText={state?.message?.url ?? " "}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12} className={classes.gridpadding}>
                <TextField
                  name="Endpoint"
                  fullWidth
                  label="End point"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  value={state?.endpoint}
                  onChange={(e) => handleChange("endpoint", e.target.value)}
                  error={state?.error?.endpoint}
                  helperText={state?.message?.endpoint ?? " "}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              spacing={0}
              justifyContent="flex-start"
            >
              <Grid item md={6}>
                <FormControl error={state?.error?.requestschema}>
                  <label>Request Schema</label>
                  <textarea
                    className={classes.textarea}
                    placeholder="Request Schema"
                    value={state.requestschema}
                    onChange={(e) =>
                      handleChange("requestschema", e.target.value, true)
                    }
                  />
                  <FormHelperText>
                    {state?.error?.requestschema === true
                      ? state?.message?.requestschema
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6} justifyContent="center">
                <FormControl error={state?.error?.responseschema}>
                  <label>Response Schema</label>
                  <textarea
                    className={classes.textarea}
                    placeholder="Response Schema"
                    value={state.responseschema}
                    onChange={(e) =>
                      handleChange("responseschema", e.target.value, true)
                    }
                    id="filled-multiline-static"
                    variant="outlined"
                  />
                  <FormHelperText>
                    {state?.error?.responseschema === true
                      ? state?.message?.responseschema
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            md={12}
            justifyContent="flex-end"
            spacing={3}
            alignItems="flex-start"
          >
            <Grid item>
              {state.isEdit && (
                <Button
                  onClick={handledelete}
                  color="secondary"
                  variant="outlined"
                >
                  delete
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                onClick={handlesaveservice}
                color="error"
                variant="outlined"
              >
                {state.isEdit ? "update" : "save"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          md={9}
          xs={9}
          sm={6}
          spacing={2}
          alignItems={"center"}
          justifyContent="center"
        >
          <Grid item>
            <Typography variant="h5">{usermsg}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default Service;
