import React, { useEffect } from "react";
import { FormRendererParent, ViewForm } from "form-configurator";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "hcc-binder";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { giveMeRoleDataSecurity } from "../../utils";


const { GET_RENDER_JSON } = actions;


function FormRenCheck(props) {
    const [data, setdata] = React.useState("");


    React.useEffect(() => {
        getFormDetail(props);
    }, [])

    const renderJson = useSelector(
        (state) => state?.renderSlice?.getRenderJson.data
    );

    const getFormDetail = (props) => {
        const roleDataSecurity = giveMeRoleDataSecurity(props?.userData);
        const createdBy = props?.userData?.user_profile_id;

        let id = "c3c176b9-5369-4a71-b0c6-bfa043ad8994";
        let entryId = props?.entryId // props?.entryId;
        let isInvestigator = props?.location?.state?.isInvestigator ?? false;

        let params = {
            formId: id,
            entryId: entryId,
            isInvestigator,
            typeViewer: props?.title,
            secToShow: [0, null],
            type: 0,
            forms: roleDataSecurity.form?.[0] === "all" ? [] : roleDataSecurity.form,
            countryIds:
                roleDataSecurity.country?.[0] === "all" ? [] : roleDataSecurity.country,
            level: {
                level1:
                    roleDataSecurity.level1?.[0] === "all" ? [] : roleDataSecurity.level1,
                level2:
                    roleDataSecurity.level2?.[0] === "all" ? [] : roleDataSecurity.level2,
                level3:
                    roleDataSecurity.level3?.[0] === "all" ? [] : roleDataSecurity.level3,
                level4:
                    roleDataSecurity.level4?.[0] === "all" ? [] : roleDataSecurity.level4,
                level5:
                    roleDataSecurity.level5?.[0] === "all" ? [] : roleDataSecurity.level5,
                level6:
                    roleDataSecurity.level6?.[0] === "all" ? [] : roleDataSecurity.level6,
                level7:
                    roleDataSecurity.level7?.[0] === "all" ? [] : roleDataSecurity.level7,
                level8:
                    roleDataSecurity.level8?.[0] === "all" ? [] : roleDataSecurity.level8,
                level9:
                    roleDataSecurity.level9?.[0] === "all" ? [] : roleDataSecurity.level9,
                level10:
                    roleDataSecurity.level10?.[0] === "all"
                        ? []
                        : roleDataSecurity.level10,
                level11:
                    roleDataSecurity.level11?.[0] === "all"
                        ? []
                        : roleDataSecurity.level11,
                level12:
                    roleDataSecurity.level12?.[0] === "all"
                        ? []
                        : roleDataSecurity.level12,
            },
        };

        if (createdBy) {
            params.created_by = createdBy;
        }

        dispatch(GET_RENDER_JSON(params));
    };

    const dispatch = useDispatch();

    const backDrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);


    const userData = useSelector((state) => state?.authSlice?.login?.data);



    const onSave = (data) => {
        let params = {
            form_id: "c3c176b9-5369-4a71-b0c6-bfa043ad8994",
            data: data?.answer,
            notifier_status: data.isDraft ? 9 : 1,
            notifyType: data?.isDraft,
            secName: data.name,
            entry_id: props?.entryId,
            isEdit: props?.entryId ? true : false,
            Provider_Id: props?.Provider_id,
            provider: "provider",
        };

        Promise.resolve(dispatch(actions.UPSERT_ENTRY(params))).then((res) => {
            backDrop.setBackDrop({
                ...backDrop,
                open: false,
            });

            if (!res.payload.error) {
                //   if (!data.isDraft && !isEdit) {
                //     setIsChangesSaved(true);
                //   }
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `Provider Details has been ${data.isDraft ? "saved as draft" : props?.entryId ? "updated" : "submitted"
                        } Successfully!!!`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                props?.close();
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong! Try Again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }
        });

    }

    return (
        <div>
            <FormRendererParent
                id="c3c176b9-5369-4a71-b0c6-bfa043ad8994"
                onSaveForm={(data) => {
                    // console.log(data, "data")
                    setdata(data);
                    onSave(data);
                }}
                entry_id={props?.entryId}
                answer={props?.entryId.length > 0 ? renderJson.answers : data?.answer}  // {data?.answer}
                btnName={props?.entryId ? "Update" : "Submit"}
                submitBtnBottom="Right"
                negativeBtn="Cancel"
                cancelPresent={false}
                headerPresent={true}
                formTitle={false}
            />
        </div>
    );
}

export default FormRenCheck;
