import React , {useEffect} from "react";
import PropTypes from "prop-types";
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";


const MySwaggerUI = (props) => {
    
  useEffect(() =>{
    SwaggerUI({
      dom_id: "#swaggerUIApp",
      spec:props.data
    });

  } , [props.data])
 
    return <div className={props.classes.SwaggerUI} id="swaggerUIApp" />;
  
}

MySwaggerUI.propTypes = {
  doc: PropTypes.string.isRequired,
};

export default MySwaggerUI;
