import React from "react";
import { makeStyles, Grid, Paper } from "@material-ui/core";
import { giveMeRoleDataSecurity } from "../../utils";
import { SubHeader } from "../../components";
import { FormBuilder } from "./formbuilder";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // padding: 16,
    [theme.breakpoints.only("xs")]: {
      padding: 10,
    },
  },
  sub: {},
  infocardGrid: {
    margin: "12px 0px -10px 20px",
  },
  infocard: {
    maxWidth: "100%",
    padding: "0px",
    margin: "12px",
  },
  cardContent: {
    //boxShadow: theme.shadows[10],
  },
  cardContent1: {
    float: "Right",
  },
  render: {
    // marginTop: "20px",
    padding: "0px 32px 32px 32px",
  },

  backBtn: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 12,
    },
    boxShadow: "0px 15px 25px #110f4712",
    backgroundColor: "#fff",
    paddingRight: theme.spacing(2),
    "&:hover": {
      boxShadow: "0px 15px 25px #110f4712",
      backgroundColor: "#fff",
      paddingRight: theme.spacing(2),
    },
  },
  treePaper: {
    padding: theme.spacing(1),
    marginBottom: 10,
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 150px)",
      marginBottom: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    border: "1px solid #0000001A",
    boxShadow: "0px 15px 25px #110f4712",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  patientdetailpaper: {
    height: "100px",
  },
  formBuilderPaper: {
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 150px)",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    overflow: "auto",
    boxShadow: "0px 15px 25px #110f4712",
    border: "1px solid #0000001A",
  },
  subbacktext: {
    opacity: 0.6,
    fontSize: 12,
    paddingLeft: 4,
  },
}));

const FormRenderer = (props) => {
  const {
    form_detail,
    tree_view,
    forms,
    parent_form_id,
    isEdit,
    secToShow,
    secName,
    answer,
    entry_id,
  } = props;
  // console.log(props, "FormRenderProps");
  const classes = useStyles();

  const [showForm, setShowForm] = React.useState(true);
  const drawerContext = React.useContext(DrawerContext);
  const [view, setView] = React.useState([]);

  const [state, setState] = React.useState({
    selected_form: forms[0],
    form_id: forms[0]?.form_id,
    answer: answer,
  });

  const onViewSectionDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.left,
      open: false,
    });
  };

  const getSectionId = (data) => {
    setState({
      ...state,
      selected_form: forms?.filter((_) => _.id === data.id)?.[0],
      form_id: data.id,
    });

    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.left,
      open: false,
      onClose: () => onViewSectionDrawerClosed(),
    });
  };

  return (
    <div className={classes.root} id="frDiv1">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        {/* Header Section */}
        {/* <Grid item>
          <SubHeader title={props.form_detail?.name} />
        </Grid> */}

        {forms.length > 0 && (
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.render}
          >
            {/* Form View */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper className={classes.formBuilderPaper} id="paper">
                {/* {!showForm && (
                  <PatientDetail
                    patientDetail={entryPatientDetails}
                    dataSecurityMappedCountryAndSelectedlevel={
                      dataSecurityMappedCountryAndSelectedlevel
                    }
                    mappedLocations={mappedLocations}
                    isEdit={isEdit}
                    onPatientSelected={onPatientSelected}
                  />
                )} */}

                {showForm && (
                  <FormBuilder
                    getSectionId={getSectionId}
                    form_id={state.form_id}
                    forms={forms}
                    parent_form_id={parent_form_id}
                    isEdit={isEdit}
                    entry_id={entry_id}
                    answer={answer}
                    isSubmitted={props?.isSubmitted}
                    setIsSubmitted={props?.setIsSubmitted}
                    // isInvestigator={isInvestigator}
                    // isReadyOnly={isReadyOnly}
                    secToShow={secToShow}
                    secName={secName}
                    // onAnswerUpdated={updateAnswer}
                    view={view}
                    setView={setView}
                    // viewSection={viewSectionDrawer}
                    // setHistoryOfViewedForms={setHistoryOfViewedForms}
                    // statusUpdateData={toggleButtonStatus.statusUpdateData}
                    // patientDetail={patientDetail}
                    // restForm={restForm}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FormRenderer;
