/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc When a route/path is not found, this page will be shown. i.e 404 - Page
 */

import React from "react";
import { Typography } from '@mui/material';

class NotFound extends React.Component {
    render() {
        return (
            <div>
                <Typography variant="h6">404 - Not Found</Typography>
            </div>
        );
    }
}

export default NotFound;
