import React, { useContext } from "react";
import Managecard, { ManageCard } from "atp-crud";
import { withNavBars } from "../../HOCs";
import { AlertContext, DialogContext, DrawerContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions, apis } from "hcc-binder";
import { AlertProps, Message } from "../../utils";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
  Avatar,
} from "@material-ui/core";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormRenCheck from "../ManageConsumer/formRenCheck";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogHeading: {
    fontFamily: "Poppins",
    fontWeight: "SemiBold",
    color: theme?.palette?.primary?.main,
    fontSize: "17px",
    textTransform: "uppercase",
    display: "grid",
    gridTemplateColumns: "50% 50%",
  },
  avatarDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
  },
  avatar: {
    width: "20px",
    height: "20px",
    borderRadius: "20%",
  },
  divider: {
    width: "20%",
  },
}));

function Viewprovider() {
  const classes = useStyles();
  const drawer = useContext(DrawerContext);
  const theme = useTheme();
  const selector = useSelector((state) => ({
    getallprovider: state?.providerSlice?.getallprovider?.data?.result,
  }));
  console.log("providr", selector.getallprovider);
  const dialogContext = React.useContext(DialogContext);
  const alertContext = React.useContext(AlertContext);
  const [state, setState] = React.useState([]);
  console.log(selector.getallprovider);

  const [open, setOpen] = React.useState(false);
  const [editProvId, setEditProvId] = React.useState("");
  const [editEntryId, setEditEntryId] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditProvId("");
    setEditEntryId("");
    setOpen(false);
  };

  const History = useHistory();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.getallprovider());
  }, [open]);

  React.useEffect(() => {
    setState(selector.getallprovider);
  }, [selector.getallprovider]);

  const addfunc = (e) => {
    handleClickOpen();
  };

  const editfunc = (value) => {
    setEditProvId(value?.currentmapobject?.Provider_Id);
    setEditEntryId(value?.currentmapobject?.entry_id);
    setOpen(true);
  };

  const deletefunc = (value) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: Message.delete_provider_title,
      body: Message.delete_provider_message,
      positiveBtn: Message.delete_provider_btn,
      negativeBtn: "Cancel",
      onOk: () => deleteprovider(value?.currentmapobject),
    });
  };

  const deleteprovider = (value) => {
    let filter = {
      filter: { _key: value._key },
    };
    let doc = {
      activestatus: false,
    };
    let params = {
      filter,
      doc,
    };
    Promise.resolve(apis.UpsertandUpdateforprovider(params))
      .then((res) => {
        dialogContext.setDialog({
          ...dialogContext,
          open: false,
        });
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.success,
          msg: Message.delete_provider_success,
          horizontal: AlertProps.horizontal.right,
          vertical: AlertProps.vertical.top,
        });
        dispatch(actions.getallprovider());
      })
      .catch((err) => {});
  };

  const searchfunc = (value) => {
    debugger;
    console.log(value);
    if (value) {
      const filter = selector.getallprovider?.filter((v) => {
        return v?.["Providers Name"]
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
      });
      console.log(filter);
      setState(filter);
    } else {
      setState(selector.getallprovider);
    }
  };
  const handlelclick = (value) => {
    History.push({
      pathname: "/providersteps",
      state: value,
    });
  };
  console.log({ state });
  return (
    <div>
      <ManageCard
        basedata={state ?? []}
        globalsrc={"/images/virus.svg"}
        title={"Manage Provider"}
        topbuttonName={"Add Provider"}
        hideFilter={true}
        showSort={false}
        sortOptions={[
          { label: "name", value: "name" },
          { label: "age", value: "age" },
        ]}
        editfunc={editfunc}
        addfunc={addfunc}
        // sortfunc={sortfunc}
        // filterfunc={filterfunc}
        searchfunc={searchfunc}
        isMoreBtn={true}
        hideAvatar={true}
        hideEditBtn={false}
        hideDeleteBtn={false}
        divider={true}
        isFilterApplied={false}
        keysformap={{
          // src_key: "first_name",
          // alt_key: "alt",
          title_key: "Providers Name",
          //   cardname_key: "",
          //   subtitle_key: "icd_10",
          // url_key: "desigination",
          // email_key: "email_id",
        }}
        hidebtn2={true}
        onBtn2Clicked={() => alert("ji")}
        Btn2Name={"btn1"}
        hidebtn1={true}
        onBtn1Clicked={() => alert("ji")}
        Btn1Name={"okoo"}
        iconButton={false}
        onActionIconBtnClicked={() => alert("null")}
        show_card={false}
        show_sub_title={false}
        deletefunc={deletefunc}
        inbuiltsearchfunctinality={false}
        hideSearchBar={false}
        hideaddbutton={false}
        isonclick={true}
        onclick={handlelclick}
        theme={theme}
      />

      <Dialog
        maxWidth="lg"
        PaperProps={{ sx: { width: "70%" } }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogHeading}>
          <h3> Provider Details</h3>
          <div className={classes.avatarDiv}>
            <Avatar
              src="/images/close.svg"
              className={classes.avatar}
              // className={`${state.direction === 'left' ? `${classes.left_large}` : `${classes.large}`} ${state?.isLarge ? classes.large_extra_large : ""}`}
              onClick={handleClose}
            />
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <FormRenCheck
            Provider_id={editProvId}
            entryId={editEntryId}
            close={handleClose}
          />
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default Viewprovider;
