import React from "react";
import Viewprovider from "./viewprovider";
import { withNavBars } from "../../HOCs/index";
function ManageProvider() {
  return (
    <div>
      <Viewprovider />
    </div>
  );
}
export default withNavBars(ManageProvider);
