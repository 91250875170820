import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SearchLocationInput from './search'

// const useStyles = makeStyles((theme) => ({
  
// }));

const LocationParent = (props) => {
  // const classes = useStyles();

    
  return (
    <div>
        <SearchLocationInput
            id={props?.id}
            value={props?.value?.address}
            label={props?.label}
            handleChange={props?.handleChange}
            isReadonly={props?.isReadonly ?? false}
            disabled={props?.disabled ?? false}
            isrequired={props?.isrequired ?? false}
            error={props?.errorValidation && props?.errorValidation?.error ? props?.errorValidation?.error : false}
            helperText={props?.errorValidation && props?.errorValidation?.errorMessage ? props?.errorValidation?.errorMessage : ''}
        />
    </div>
  );
};
LocationParent.propTypes = {
  value:PropTypes.string,
  label:PropTypes.string,
  id:PropTypes.string,
  handleChange:PropTypes.func,
};

export default LocationParent;
