import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import { SubHeader } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { default as ConsumerStepper } from "./consumerStepper";
import FormRendererParent from "../formRenderer";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    height: "100%",
    borderRadius: "8px",
    boxSizing: " border-box",
  },
  content: {
    width: "100%",
    height: "calc(100% - 53px)",
    overflowY: "auto",
    backgroundColor: "#ffffff",
  },
  container: {
    zIndex: 1,
    position: "relative",
  },
}));

const AddConsumer = (props) => {
  const history = useHistory();
  const onBackBtnClicked = () => {
    history.push(Routes.ManageConsumer);
  };

  const classes = useStyles();

  console.log("PropsState -", props?.location?.state);

  return (
    <Paper className={classes.root}>
      <Grid container>
        <SubHeader
          //   searchField={false}
          title={"Create New Consumer"}
          hideBackBtn={false}
          backToToolip={"Back To Consumer"}
          onBackBtnClicked={onBackBtnClicked}
          hideSearch={true}
          hideHeaderBtn={true}
        />
      </Grid>
      <Box className={classes.content}>
        <ConsumerStepper contentData={props?.location?.state} />
      </Box>
    </Paper>
  );
};

export default withRouter(withNavBars(AddConsumer));
