import React from "react";
import { withNavBars } from "../../HOCs";
import { Typography } from "@material-ui/core";

const Dashboard = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h3" align="center">
        Welcome to <br />
        HCC 
      </Typography>
    </div>
  );
};

export default withNavBars(Dashboard);
