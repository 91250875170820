import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Grid, IconButton } from "@material-ui/core";
import { Routes } from "../../router/routes";

import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
// import Content from "./content";
import { withNavBars } from "../../HOCs/index";
import { useHistory, useLocation } from "react-router-dom";
import ContentMapppings from "./ContentMapppings";
// import Service from "./serviceregistrystepper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "10px",
    // backgroundColor: theme.palette.background.paper,
  },
}));

function ConsumerSteps() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  const handleChangefortab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position="static"> */}
      <Grid container alignItems="center" spacing={2} md={12}>
        <Grid item>
          <IconButton
            size="small"
            onClick={() => history.push(Routes.ManageConsumer)}
          >
            <ArrowBackIosRoundedIcon
              fontSize="small"
              style={{ color: "black" }}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChangefortab}
            aria-label="simple tabs example"
          >
            <Tab label="Content Mapping" {...a11yProps(0)} />
            {/* <Tab label="Service" {...a11yProps(1)} /> */}
          </Tabs>
        </Grid>
      </Grid>
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        <ContentMapppings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* <Service state={location?.state} /> */}
      </TabPanel>
    </div>
  );
}

export default withNavBars(ConsumerSteps);
