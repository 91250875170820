/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all the routes used in the application.
 */

const Routes = {
  //Auth routes
  home: "/",
  dashboard: "/Home",
  login: "/login",
  AccessManagement: "/accessmanagement",
  GeneralMaster: "/generalmaster",
  Swagger: "/viewContent",
  manageprovider: "/manageprovider",
  createmanageprovider: "/createprovider",
  providersteps: "/providersteps",
  ManageConsumer: "/manageconsumer",
  AddConsumer: "/addconsumer",
  FormRenderer: "./formrenderer",
  CreateConsumer: "/createconsumer",
  ContentMapping: "/contentmapping",
};

export { Routes };
