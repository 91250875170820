import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core";
import { Button, Divider } from '@material-ui/core';
import { Typography } from '@mui/material';

const styles = makeStyles((theme) => ({
    editButton: {
        marginLeft: "10px",
        backgroundColor: "#0071F2",
        borderRadius: "0px",
        color: "#fff",
    },
    appbarstyle: {
        backgroundColor: "#fff",
        height: "85vh",
        boxShadow: 'none !important',
        marginTop:"10px"
    },
    Tabsstyle: {
        flexDirection: "inherit !important",
        justifyContent: "left !important"
    },
    iconLabelWrapper2: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    leftHeading: {
        fontFamily:theme,
        fontSize: "19px",
        padding: "10px",
        fontWeight: "bold",
        textTransform:"uppercase"
    },
    leftsidebar: {
        padding:"10px",
        backgroundColor: "#fff",
        border:"1px solid #e7dfdf",
        boxShadow: "0 10px 6px -6px #777",
        borderRadius:"12px"
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LeftTabs = (props) => {
    const classes = styles();
    const { contentData, value, handleChange } = props;

    return (
        <>
            <div className={classes.leftsidebar}>
                <div className={classes.SearchButton}>
                    <Typography variant="h6" className={classes.leftHeading}> CONTENT </Typography>
                </div>
                <Divider />
                <AppBar position="static" color="default" className={classes.appbarstyle} >
                    <Tabs value={value}

                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        aria-label="icon position tabs example"
                        indicatorColor="default"
                        orientation="vertical"
                        className={classes.Tabsstyle}
                    >
                        {contentData && contentData.length ?
                            contentData.map((datas, index) => {
                                return (

                                    <Tab key={index}
                                        onClick={(e) => handleChange(datas)}
                                        classes={{
                                            wrapper: classes.iconLabelWrapper2,
                                            //labelContainer: classes.labelContainer
                                        }}
                                        label={datas.Content_Name} {...a11yProps(index)}>
                                    </Tab>

                                )
                            })
                            : ""
                        }
                    </Tabs>
                </AppBar>
            </div>
        </>
    )
}

export default LeftTabs