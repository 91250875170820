import { apis } from "hcc-binder";
import { uuid } from "uuidv4";

export const addclient = (clientname) => {
  return new Promise((resol, rejec) => {
    let doc = {
      projectname: "client_vs_db_stg",
      client: clientname,
      projectId: "a4dcb791-23bb-4b1c-851c-0af8132cd91f",
      clientId: "98ba07cc-3657-4cf0-a1aa-637e66e49e6f",
      active: "Y",
    };
    Promise.resolve(apis.UpsertClient(doc))
      .then((res) => {
        resol(res);
      })
      .catch((err) => {
        rejec(err);
        console.log(err);
      });
  });
};

export const AddProject1 = (payload) => {
  return new Promise((resol, rejec) => {
    //debugger;
    let {
      client,
      projectname,
      storagetype,
      description,
      uploadurl,
      metadataname,
      clientId,
      clientname,
      projectId,
    } = payload;

    let doc = {
      client: client,
      db_name: "undefined",
      projectname: projectname,
      storagetype: storagetype,
      description: description,
      uploadurl: uploadurl,
      metadataId: "",
      metadataname: metadataname,
      attributes: [
        {
          theme: "",
          user: "",
        },
      ],
      metadata: [],
      projectId: projectId,
      entity_relationship: [],
      clientId: clientId,
      client: clientname,
    };
    Promise.resolve(apis.UpserAddProject1({ doc }))
      .then((res) => {
        resol(res);
      })
      .catch((err) => {
        rejec(err);
        console.log(err);
      });
  });
};

export const AddProject2 = () => {
  return new Promise((resol, rejec) => {
    //debugger;
    let doc = {
      doc: {
        attributes: [],
      },
    };
    Promise.resolve(apis.UpserAddProject2(doc))
      .then((res) => {
        resol(res);
      })
      .catch((err) => {
        rejec(err);
        console.log(err);
      });
  });
};

export const AddDatabase = (dbname) => {
  return new Promise((resol, rejec) => {
    let doc = {
      dbname,
    };
    Promise.resolve(apis.InsertAddDB(doc))
      .then((res) => {
        resol(res);
      })
      .catch((err) => {
        console.log(err);
        rejec(err);
      });
  });
};

export const AddCollection = (db_name) => {
  return new Promise((resol, rejec) => {
    let doc = {
      ...db_name,
    };
    Promise.resolve(apis.InsertAddCollection(doc))
      .then((res) => {
        resol(res);
      })
      .catch((err) => {
        console.log(err);
        rejec(err);
      });
  });
};

export const AddProjectconfig = (doc) => {
  return new Promise((resol, rejec) => {
    Promise.resolve(apis.AddProjectConfig(doc))
      .then((res) => {
        resol(res);
      })
      .catch((err) => {
        console.log(err);
        rejec(err);
      });
  });
};
