import React from "react";
import { makeStyles } from "@material-ui/core";
import Navbar from "../components/navbar";
import { SensibleDrawer, TopNavBar } from "../components";
import { checkWithIdm } from "atp-casbin-js";
import { NavBarArr } from "../utils";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { AuthContext } from "../contexts";
import axios from "axios";
import {VaultRequest} from "vault-request";

const useStyles = makeStyles((theme) => ({
  root: {
    height:"100%",
    
  },
  content: {
    width: "100%",
    height: "calc(100% - 64px)",
    overflow: "auto",
  },
}));

const withNavBars = (Component) => (props) => {
  let history = useHistory();
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [userInfo, setUserInfo] = useState("");
  const [navlist, setNavlist] = useState([]);
  const [RoleName , setRoleName] = useState("");

  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      GetUserName();
      GetRepoList();
      GetRoleName();
    
    } else {
      history.push("/");
      localStorage.clear();
    }

  }, []);

  const GetUserName = () => {
    const token = localStorage.getItem("access_token");
    const parsedToken = token?.split(".")[1];
    const userInfos = JSON.parse(window.atob(parsedToken));
    setUserInfo(userInfos);
    auth.setAuth(userInfo);
  };

  const GetRepoList = async () => {
    let roleid = localStorage.getItem("RoleId");
    let roledata = atob(localStorage.getItem("permissionData"));
    let _roledata = JSON.parse(roledata);
    let repository = _roledata.find(
      (l) => l?.permissionRepo?.route_url === window.location.pathname
    );

    // setSelectedItem(repository);

    let hasAccessCheck = await checkWithIdm(
      _roledata,
      roleid,
      repository?.permissionRepo?.rep_name,
      "read"
    );

    if (hasAccessCheck) {
      let NlistCheckRole = NavBarArr.map(async (l) =>
        (await checkWithIdm(_roledata, roleid, l?.repo, "read"))
          ? { ...l, read: true }
          : { ...l, read: false }
      );
      Promise.all(NlistCheckRole).then((values) => {
        const Navbarlist = values.filter((li) => li.read === true);
        setNavlist(Navbarlist);
      });
    } else {
      history.push("/");
      localStorage.clear();
    }
  };
  const GetRoleName = async () => {
    let roleid = localStorage.getItem("RoleId");

    let formEntires = {
      db_name: "hcc",
      entity: "IDM_PermissionRoleMapping",
      filter: `IDM_PermissionRoleMapping.roleid== '${roleid}' && IDM_PermissionRoleMapping.activestatus==true`,
      return_fields: "IDM_PermissionRoleMapping",
    };

    let configEn = {
      method: "POST",
      url: `https://arangodbservice.dev.ainqaplatform.in/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: formEntires,
    };

    var formEn = await axios(configEn).then((res) => {
      return res.data.result;
    });

    setRoleName(formEn[0]?.rolename)
  }

  

  return (
    <div className={classes.root}>
      {/* <Navbar Component={Component} {...props} /> */}
      <div>
        <TopNavBar user={userInfo?.name} role={RoleName} />
      </div>
      <div className={classes.content}>
        <SensibleDrawer
          sidePanellist={navlist}
          Component={Component}
          {...props}
        />
      </div>
    </div>
  );
};

export default withNavBars;
