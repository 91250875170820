import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Content from "./content";
import { withNavBars } from "../../HOCs/index";
import { useHistory, useLocation } from "react-router-dom";
import Service from "./serviceregistrystepper";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Grid, IconButton } from "@material-ui/core";
import { Routes } from "../../router/routes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#F9F9F9",
  },
  tab: {
    color: theme.palette.primary,
  },
}));

function ProviderSteps() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  const handleChangefortab = (event, newValue) => {
    setValue(newValue);
  };

  //   const location = useLocation();
  //   console.log({ location });
  //   const isEdit = Boolean(location.state);
  //   const history = useHistory();
  //   const dispatch = useDispatch();
  //   const backdrop = useContext(BackdropContext);
  //   const drawer = useContext(DrawerContext);
  //   const selector = useSelector((state) => ({
  //     getallprovider: state?.providerSlice?.getallprovider.data.result,
  //     getallcontents: state?.providerSlice?.getcontents?.data?.result,
  //     getsinglecontent: state?.providerSlice?.getsinglecontent?.data?.result,
  //   }));
  //   const { state } = props;
  //   const [activeStep, setActiveStep] = React.useState(0);
  //   const steps = getSteps();
  //   const [skipped, setSkipped] = React.useState(new Set());
  //   const [childrenstate, setChildrenstate] = React.useState({
  //     // firstName: isEdit ? location?.state?.First_Name : "",
  //     // lastName: isEdit ? location?.state?.Last_Name : "",
  //     // email: isEdit ? location?.state?.Email : "",
  //     // phoneNumber: isEdit ? location?.state?.Phone_Number : "",
  //     // address: isEdit ? location?.state?.Address : "",
  //     // pincode: isEdit ? location?.state?.Pincode : "",
  //     // city: isEdit ? location?.state?.City : "",
  //     // state: isEdit ? location?.state?.State : "",
  //     // country: isEdit ? location?.state?.Country : "",
  //     provider_ID: uuidV4(),
  //     //for service
  //     apiname: "",
  //     httpmethod: {},
  //     url: "",
  //     requestschema: "",
  //     responseschema: "",
  //     currentContentID: "",
  //     currentContentName: "",
  //     currentContentType: "",
  //     totalContent: [],
  //     currentlyaddedcontent: [],
  //   });

  //   console.log({ childrenstate });
  //   console.log(env.REACT_APP_FORM_ID);
  //   const handleChange = (key, value, istextarea, fullset) => {
  //     //debugger;
  //     try {
  //       if (typeof value === "string") {
  //         setChildrenstate({ ...childrenstate, [key]: value });
  //       } else if (typeof value === "object" && istextarea) {
  //         let beatifiedvalue = JSON.stringify(value, null, 1);
  //         setChildrenstate({ ...childrenstate, [key]: beatifiedvalue });
  //       } else if (typeof value === "object") {
  //         if (Array.isArray(value)) {
  //           if (fullset) {
  //             setChildrenstate({
  //               ...childrenstate,
  //               [key]: [...value],
  //             });
  //           } else {
  //             setChildrenstate({
  //               ...childrenstate,
  //               [key]: [...childrenstate?.[key], ...value],
  //             });
  //           }
  //         } else {
  //           setChildrenstate({
  //             ...childrenstate,
  //             [key]: { ...childrenstate?.[key], ...value },
  //           });
  //         }
  //       }
  //     } catch (err) {
  //       alert("error on object");
  //       console.log({ err });
  //     }
  //   };
  //   const isStepOptional = (step) => {
  //     return step === (3 || 4 || 5);
  //   };
  //   const isStepSkipped = (step) => {
  //     return skipped.has(step);
  //   };

  //   React.useEffect(() => {
  //     //debugger;
  //     dispatch(actions.getallcontenttype());
  //     dispatch(actions.getallcontents());
  //     dispatch(actions.getallstorage());
  //   }, []);
  //   React.useEffect(() => {
  //     if (isEdit) {
  //       // let filteredcontent = selector?.getallcontents?.filter((v) => {
  //       //   console.log(
  //       //     v.Content_ID,
  //       //     location?.state?.Content_ID?.indexOf(v.Content_ID) > -1
  //       //   );
  //       //   return location?.state?.Content_ID?.indexOf(v.Content_ID) > -1;
  //       // });
  //       // console.log(
  //       //   "filteredcontent",
  //       //   filteredcontent,
  //       //   location?.state?.Content_ID
  //       // );
  //       // setChildrenstate({
  //       //   ...childrenstate,
  //       //   totalContent: filteredcontent ?? [],
  //       // });
  //     }
  //   }, [isEdit]);

  //   React.useEffect(() => {
  //     setChildrenstate({
  //       ...childrenstate,
  //       totalContent: selector.getsinglecontent ?? [],
  //     });
  //   }, [selector.getallcontents]);

  //   // React.useEffect(() => {}, [childrenstate.totalContent]);

  //   const delecontent = (data) => {
  //     let __params = {
  //       activestatus: false,
  //     };
  //     let filter = { filter: { _id: `${data?._id}` } };
  //     backdrop.setBackDrop({
  //       ...backdrop,
  //       open: true,
  //       message: Message?.deletecontent,
  //     });
  //     let apidata = {
  //       dbname: env.REACT_APP_DB,
  //       entity: "Content",
  //       ismeta: true,
  //       metadataid: env.REACT_APP_METADATAID,
  //       metadatadbname: env.REACT_APP_METADATA_DB_NAME,
  //       filter: filter,
  //       doc: __params,
  //     };
  //     Promise.resolve(apis.UpsertandUpdate(apidata))
  //       .then((res) => {
  //         console.log(res);
  //         drawer.setDrawer({
  //           open: false,
  //         });
  //         dispatch(actions.getallcontents());
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   const handlesaveservice = () => {
  //     //debugger;
  //     let data = {
  //       Service_ID: uuidV4(),
  //       Service_URL: childrenstate.url,
  //       Method: childrenstate.httpmethod?.value,
  //       Request_Schema: childrenstate.requestschema,
  //       Response_Schema: childrenstate.responseschema,
  //       Api_Name: childrenstate.apiname,
  //     };
  //     let params = {
  //       doc: data,
  //     };

  //     Promise.resolve(apis.UpsertandUpdateforservice(params))
  //       .then((res) => {
  //         //debugger;
  //         console.log(res);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   function getStepContent(step) {
  //     switch (step) {
  //       case 0:
  //         return (
  //           // <BasicDetails state={childrenstate} handleChange={handleChange} />
  //           <FormRendererParent
  //             id="c3c176b9-5369-4a71-b0c6-bfa043ad8994"
  //             secToShow={[0, null]}
  //             secName={0}
  //             /* entryId="06c2ceee-4bb6-455a-9116-cf7395e779ec" */
  //           />
  //         );
  //       case 1:
  //         return (
  //           <Content
  //             state={childrenstate}
  //             submitContent={submitContent}
  //             handleChange={handleChange}
  //             editdelefunc={editdelecontent}
  //           />
  //         );
  //       case 2:
  //         return <Service state={childrenstate} handleChange={handleChange} />;
  //       default:
  //         return "Unknown step";
  //     }
  //   }

  //   const validation = (keys) => {
  //     for (let x of keys) {
  //       if (childrenstate[keys]?.length === 0) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   };

  //   const submitContent = async () => {
  //     // debugger;
  //     // const isValidated = validation();
  //     // if (!isValidated) {
  //     //   return false;
  //     // }

  //     console.log(env.REACT_APP_FORM_ID);
  //     let __params = [];
  //     childrenstate.totalContent.forEach((v) => {
  //       debugger;
  //       let obj = {
  //         Content_ID: v.Content_ID,
  //         Content_Name: v.Content_Name,
  //         Content_Type: v.Content_Type,
  //         Storage: v.Storage,
  //         Provider_ID: childrenstate.provider_ID,
  //       };

  //       if (Boolean(v?.isnew)) {
  //         __params.push(obj);

  //         let addproject1payload = {
  //           client: "sweeety",
  //           db_name: "undefined",
  //           projectname: v.Content_Name?.trim(),
  //           storagetype: "Arango",
  //           description: "autogenerated",
  //           uploadurl: env.REACT_APP_Arrango_Uploadurl,
  //           metadataId: "",
  //           metadataname: v.Content_Name?.trim(),
  //           attributes: [
  //             {
  //               theme: "",
  //               user: "",
  //             },
  //           ],
  //           metadata: [],
  //           entity_relationship: [],
  //         };
  //         let addDbPayload = {
  //           dbname: `sweeety_${v.Content_Name?.trim()}`,
  //         };
  //         let addprojectconfig = {
  //           projectName: v.Content_Type,
  //           clientName: "sweety",
  //           dataStorage: `sweeety_${v.Content_Name?.trim()}`,
  //           connectionParams: {
  //             ArangoUrl:env.REACT_APP_Arrango_Uploadurl,
  //           },
  //           type: "Arango",
  //         };
  //         // AddProject1(addproject1payload);
  //         // AddProject2();
  //         AddDatabase(addDbPayload);
  //         AddCollection(addDbPayload);
  //         // await AddProjectconfig(addprojectconfig);
  //       } else if (Boolean(v?.isedit)) {
  //         __params.push(obj);
  //       }
  //     });
  //     console.log({ __params });

  //     // let __params = {
  //     //   Content_ID: uuidV4(),
  //     //   Content_Name: state.contentName,
  //     //   Content_Type: state.contentType?._id,
  //     // };

  //     let filter = {
  //       filter: {
  //         Provider_ID: `${location.state?.Providerdetails?.Provider_ID}`,
  //       },
  //     };
  //     backdrop.setBackDrop({
  //       ...backdrop,
  //       open: true,
  //       message: props.isEdit ? Message.updatingcontent : Message.addcontent,
  //     });

  //     let apidata = {
  //       dbname: env.REACT_APP_DB,
  //       entity: "Content",
  //       ismeta: true,
  //       metadataid: env.REACT_APP_METADATAID,
  //       metadatadbname: env.REACT_APP_METADATA_DB_NAME,
  //       filter: props?.isEdit ? filter : undefined,
  //       doc: __params,
  //     };
  //     Promise.resolve(apis.UpsertandUpdate(apidata))
  //       .then((res) => {
  //         console.log(res);
  //         drawer.setDrawer({
  //           open: false,
  //         });
  //         dispatch(actions.getallcontents());
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   console.log("icid", childrenstate);

  return (
    <div className={classes.root}>
      <div className={classes.appbar}>
        <Grid container alignItems="center" md={12}>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => history.push(Routes.manageprovider)}
            >
              <ArrowBackIosRoundedIcon
                fontSize="small"
                style={{ color: "black" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Tabs
              value={value}
              onChange={handleChangefortab}
              aria-label="simple tabs example"
              indicatorColor="secondary"
              textColor="secondary"
              className={classes.tab}
            >
              <Tab label="Content" {...a11yProps(0)} />
              <Tab label="Service" {...a11yProps(1)} />
            </Tabs>
          </Grid>
        </Grid>
      </div>
      <TabPanel value={value} index={0}>
        <Content
          state={location?.state}
          //   submitContent={submitContent}
          //   handleChange={handleChange}
          //   editdelefunc={editdelecontent}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Service state={location?.state} />
      </TabPanel>
    </div>
  );
}

export default withNavBars(ProviderSteps);
