/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-13
 * @modify date 2021-10-18
 * @desc Sub Header with back button,title and subtitle.
 */

import React from "react";
import {
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
// import { CustomIppcToggleButtonComponent } from '../toggleButton';
import { CustomIppcToggleButtonComponent } from "../customToogleBtnForIppc";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import { ArchivedDialog, LocationLevel } from "../../components";
// store
import { actions } from "hcc-binder";
import { useDispatch, useSelector } from "react-redux";
import {
  Message,
  notifyTypeBesedOnEntryStatus,
  giveMeRoleDataSecurity,
} from "../../utils";

import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { Tooltip } from "@material-ui/core";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

// Store Action's list
const { UPDATE_ENTRY_STATUS, TRANSFER_ENTRY } = actions;

const useStyles = makeStyles((theme) => ({
  root: {},
  subTitle: {
    marginLeft: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  divider: {
    margin: 10,
  },
  viewSection: {
    padding: "5px 12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 8px #110F471A",
    border: "1px solid #110F471A",
    borderRadius: 7,
    // [theme.breakpoints.up('md')]: {
    //     display: 'none'
    // }
  },
  fromlocation: {
    marginTop: "5px",
    marginBottom: "10px",
  },
  mappedLocation: {
    marginTop: "5px",
    marginBottom: "10px",
  },
  patientDetail: {
    marginLeft: 15,
    "& p": {
      display: "flex",
      alignItems: "center",
    },
    "& span": {
      fontSize: "1rem",
    },
    "& hr": {
      margin: 7,
      width: "1.5px",
      backgroundColor: "#000",
    },
  },
  info1: {
    color: theme.palette.primary.main,
  },
  info2: {
    color: theme.palette.primary.main,
  },
}));

export const SubHeader = ({
  hideBackBtn = false,
  hideTitle = false,
  hideSubTitle = false,
  title = "",
  transferDetails = [],
  subTitle = "",
  onBackBtnClicked = () => false,
  showToogleButton = false,
  toggleButtonData = [],
  selected = {},
  exclusive = false,
  onSelected = () => false,
  onActionIconBtnClicked = () => false,
  showInfoButton = false,
  showDivider = false,
  drawerButton = false,
  drawerButtonAction = () => false,
  statusUpdateData = {},
  statusPath = "",
  view,
  isReadyOnly,
  secName = "",
  redirectPath = "",
  getPatientDetails,
  showPatientDetails = false,
  mappedLocations = [],
  dataSecurityMappedCountryAndSelectedlevel = {},
  onPatientInfoBtnClicked = () => false,
  noBackGround = false,
  // setView
  parent_form_id = "",
}) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);

  // Declaring Store dispatch
  const dispatch = useDispatch();

  //show or hide dialog
  const [open, setOpen] = React.useState(false);

  const [reason, setReason] = React.useState({});

  const initOptions = [
    { label: "wrong location", value: "wrong location" },
    { label: "duplication", value: "duplication" },
    { label: "invalid information", value: "invalid information" },
    { label: "not fit case definition", value: "not fit case definition" },
    { label: "Others", value: "Others" },
  ];

  // const [optionsValue, setOptionsValue] = React.useState({});

  const [selectedData, setSelectedData] = React.useState(selected ?? {});

  const [toLocation, setToLocation] = React.useState({});

  const userData = useSelector((state) => state?.authSlice?.login?.data);

  const roleDataSecurity = giveMeRoleDataSecurity(userData);

  const history = useHistory();

  //dialog inside textField
  const [state, setState] = React.useState({
    archivedText: "",
    error: {
      archivedText: false,
    },
  });

  //set state the data and status
  const [statusData, setStatusData] = React.useState({
    status: "",
    data: {},
  });
  const blockInvalidChar = (e) =>
    [
      "#",
      "@",
      "&",
      "-",
      "+",
      "$",
      "%",
      "^",
      "*",
      "(",
      ")",
      "{",
      "}",
      "[",
      "]",
      ";",
      "|",
      "/",
      "/",
      "`",
      "~",
      "?",
      "<",
      ">",
    ].includes(e.key) && e.preventDefault();

  const onChangeInput = (e) => {
    setState({ ...state, archivedText: e.target.value });
  };

  const changeState = (value) => {
    setReason(value);
  };

  React.useEffect(() => {
    if (reason?.value !== "Others") {
      setState({ ...state, archivedText: reason?.value });
    } else {
      setState({ ...state, archivedText: "" });
    }
    // eslint-disable-next-line
  }, [reason]);

  //changing status
  const statusUpdateFunc = (status) => {
    setStatusData({
      ...statusData,
      status,
      data: statusUpdateData,
    });

    if (status === 4 || status === 10 || status === 11) {
      setOpen(true);
    } else {
      onStatusUpdate(status, statusUpdateData);

      setSelectedData(status);
    }
  };

  //dialog close
  const handleClose = () => {
    setOpen(false);
    setState({ ...state, archivedText: "" });
  };

  const giveMeNotifyType = (newStatus) => {
    switch (secName) {
      case 0:
        return notifyTypeBesedOnEntryStatus.notify[newStatus];

      case 1:
        return notifyTypeBesedOnEntryStatus.verify[newStatus];

      case 2:
        return notifyTypeBesedOnEntryStatus.register[newStatus];

      case 3:
        return notifyTypeBesedOnEntryStatus.investigate[newStatus];

      default:
        return "";
    }
  };

  const onStatusUpdate = (status, data, message) => {
    let reason;

    if (status === 4) {
      reason = "reason_for_archived";
    } else if (status === 11) {
      reason = "reason_for_transfer";
    } else if (status === 10) {
      reason = "reason_for_reject";
    }

    let __params = {
      ...data,
      notifyType: giveMeNotifyType(status),
      secName,
      [statusPath]: status,
      [reason]: message,
      reason: message,
    };

    if (status === 11 && secName === 1) {
      __params.transfer_status = "Pending";

      __params.from_location = {
        country: roleDataSecurity.country,
        level1: roleDataSecurity.level1,
        level2: roleDataSecurity.level2,
        level3: roleDataSecurity.level3,
        level4: roleDataSecurity.level4,
        level5: roleDataSecurity.level5,
        level6: roleDataSecurity.level6,
        level7: roleDataSecurity.level7,
        level8: roleDataSecurity.level8,
        level9: roleDataSecurity.level9,
        level10: roleDataSecurity.level10,
        level11: roleDataSecurity.level11,
        level12: roleDataSecurity.level12,
      };
      // tolocation
      __params.to_location = {
        country: toLocation?.country?.value ? [toLocation?.country?.value] : [],
        level1: toLocation?.level1?.value ? [toLocation?.level1?.value] : [],
        level2: toLocation?.level2?.value ? [toLocation?.level2?.value] : [],
        level3: toLocation?.level3?.value ? [toLocation?.level3?.value] : [],
        level4: toLocation?.level4?.value ? [toLocation?.level4?.value] : [],
        level5: toLocation?.level5?.value ? [toLocation?.level5?.value] : [],
        level6: toLocation?.level6?.value ? [toLocation?.level6?.value] : [],
        level7: toLocation?.level7?.value ? [toLocation?.level7?.value] : [],
        level8: toLocation?.level8?.value ? [toLocation?.level8?.value] : [],
        level9: toLocation?.level9?.value ? [toLocation?.level9?.value] : [],
        level10: toLocation?.level10?.value ? [toLocation?.level10?.value] : [],
        level11: toLocation?.level11?.value ? [toLocation?.level11?.value] : [],
        level12: toLocation?.level12?.value ? [toLocation?.level12?.value] : [],
      };
    }

    if (status === 11 && secName === 1) {
      Promise.resolve(dispatch(TRANSFER_ENTRY(__params))).then((res) => {
        let resStatus = res.payload.error;
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: resStatus
            ? "Status update Unsuccessful !"
            : "Status update Successful !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

        if (!resStatus) {
          history.push({
            pathname: Routes.verifications,
            search: `?id=${parent_form_id}&tab=2`,
          });
        }
      });
    } else {
      Promise.resolve(dispatch(UPDATE_ENTRY_STATUS(__params))).then((res) => {
        let resStatus = res.payload.error;
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: resStatus
            ? "Status update Unsuccessful !"
            : "Status update Successful !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

        if (!resStatus) {
          if (status === 3 && secName === 1) {
            history.push({
              pathname: Routes.verifications,
              search: `?id=${parent_form_id}&tab=1`,
            });
          }

          if (status === 10 && secName === 1) {
            history.push({
              pathname: Routes.verifications,
              search: `?id=${parent_form_id}&tab=3`,
            });
          }

          if (status === 8 && secName === 2) {
            history.push({
              pathname: Routes.case_registration,
              search: `?id=${parent_form_id}&tab=1`,
            });
          }

          if (status === 10 && secName === 2) {
            history.push({
              pathname: Routes.case_registration,
              search: `?id=${parent_form_id}&tab=3`,
            });
          }

          if (status === 11 && secName === 2) {
            history.push({
              pathname: Routes.case_registration,
              search: `?id=${parent_form_id}&tab=2`,
            });
          }

          if (status === 6 && secName === 3) {
            history.push({
              pathname: Routes.investigations,
              search: `?id=${parent_form_id}&tab=1`,
            });
          }
        }
      });
    }
  };

  //clicking confirm button on dialog
  const confirmUpdateStatus = () => {
    if (state.archivedText.trim().length === 0) {
      state.error = {
        archivedText: state?.archivedText?.trim()?.length === 0 ? true : false,
      };
      setState({ ...state });
    } else {
      onStatusUpdate(statusData.status, statusData.data, state.archivedText);
      setSelectedData(statusData.status);
      handleClose();

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Status updated !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  //clicking cancel button on dialog
  const cancelUpdateStatus = () => {
    state.error = {
      archivedText: false,
    };
    setState({ ...state });
    handleClose();

    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Status update cancelled !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const returnDisabled = () => {
    if (
      [2, 3, 11].indexOf(statusUpdateData?.verifier_status ?? 0) > -1 &&
      secName === 0
    ) {
      return true;
    } else if (statusUpdateData?.registration_status === 8 && secName === 1) {
      return true;
    } else if (statusUpdateData?.investigator_status === 6 && secName === 2) {
      return true;
    } else {
      return false;
    }
  };

  const giveMeLevelString = (row) => {
    let levelString = "";
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((l) => {
      let currentLevelKey = `level${l}`;
      let nextKey = l + 1;
      let nextLevelKey = `level${nextKey}`;
      if (row?.[currentLevelKey]?.label) {
        levelString += row?.[currentLevelKey]?.label;
      }

      if (row?.[nextLevelKey]?.label) {
        levelString += " -->  ";
      }
      return l;
    });

    return levelString ? `${row.country.label} ---> ${levelString}` : "-";
  };

  React.useEffect(() => {
    giveMeLevelString(mappedLocations[0]);
    // eslint-disable-next-line
  }, [title]);

  const setLocationValue = (values) => {
    setToLocation(values);
  };

  const getLabel = () => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {"Reason ?"}{" "}
        {
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        }
      </Typography>
    );
  };
  const getLabelForReject = () => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {"Reason For Reject ?"}{" "}
        {
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        }
      </Typography>
    );
  };

  const sec = () => {
    if (secName === 1) {
      return true;
    } else if (secName === 2) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={classes.root}
      style={{
        background: noBackGround
          ? "#F8F8F8"
          : "#F0F0FA 0% 0% no-repeat padding-box",
      }}
    >
      <Grid
        container
        direction="row"
        // direction='column'
        // justify="center"
        alignItems="center"
      >
        <Grid item style={{ flex: 1 }}>
          <div className={classes.header}>
            {!hideBackBtn && (
              <Grid item>
                <IconButton
                  size="small"
                  id="BackBtn"
                  onClick={onBackBtnClicked}
                  style={{
                    padding: "5px",
                    marginLeft: "18px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #E0E0E0",
                    opacity: 1,
                  }}
                >
                  <KeyboardBackspace />
                </IconButton>
              </Grid>
            )}

            {!hideTitle && (
              <Grid item>
                <Typography
                  component="h6"
                  color="textPrimary"
                  style={{ fontWeight: "bold", marginLeft: "10px" }}
                >
                  {title}
                </Typography>
              </Grid>
            )}

            {showPatientDetails && (
              <Grid item className={classes.patientDetail}>
                <Typography variant="body1" color="textPrimary">
                  {getPatientDetails && getPatientDetails?.patient_name && (
                    <Typography variant="h6" color="textPrimary">
                      {`(${
                        getPatientDetails?.patient_name?.title?.label ?? ""
                      } ${getPatientDetails?.patient_name?.first_name ?? ""} ${
                        getPatientDetails?.patient_name?.last_name?.[0] ?? ""
                      })`}
                      <Tooltip title={"View Patient Details"}>
                        <IconButton
                          id="infoBtn"
                          onClick={onPatientInfoBtnClicked}
                          className={classes.info1}
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  )}
                  {getPatientDetails && getPatientDetails?.icNumber && (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      style={{ marginLeft: 10 }}
                    >
                      {getPatientDetails?.icNumber ?? ""}
                    </Typography>
                  )}
                  {getPatientDetails && getPatientDetails?.passportNumber && (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <Typography variant="caption" color="textPrimary">
                        {getPatientDetails?.passportNumber ?? ""}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Grid>
            )}

            {/* {!hideSubTitle && <Grid item className={classes.subTitle}>
                        <Typography variant="caption">
                            {subTitle}
                        </Typography>
                    </Grid>} */}
          </div>
          {drawerButton && (
            <Button
              id="drawerBtn"
              variant="text"
              className={classes.viewSection}
              onClick={drawerButtonAction}
              color="secondary"
            >
              <div style={{ marginRight: 5, display: "flex" }}>
                {" "}
                <MenuIcon />{" "}
              </div>
              View Sections
            </Button>
          )}
        </Grid>

        {showToogleButton && (
          <Grid item>
            <CustomIppcToggleButtonComponent
              toggleButtonData={toggleButtonData}
              isSelected={selectedData}
              transferStatus={transferDetails[0]?.transfer_status}
              exclusive={exclusive}
              btnHeight={"32px"}
              // onSelected={onSelected}
              parent_form_id={parent_form_id}
              onSelected={statusUpdateFunc}
              disabled={returnDisabled()}
              borderStyle
            />
          </Grid>
        )}

        {
          <ArchivedDialog
            width="70%"
            open={open}
            dialogTitle={
              statusData.status === 4
                ? Message.archive_reason
                : statusData.status === 10
                ? Message.reject_reason
                : Message.transfer_reason
            }
            dialogContent={
              <>
                {statusData.status === 11 && secName !== 2 && (
                  <Typography varient="h6" className={classes.fromlocation}>
                    {"From Location"}
                  </Typography>
                )}
                {statusData.status === 11 && secName !== 2 && (
                  <Typography
                    varient="body2"
                    className={classes.mappedLocation}
                  >
                    {giveMeLevelString(mappedLocations[0])}
                  </Typography>
                )}

                {statusData.status === 11 && secName !== 2 && (
                  <LocationLevel
                    // id={data?.id ?? index}
                    primeLocation={
                      dataSecurityMappedCountryAndSelectedlevel?.country
                    }
                    label={"to location"}
                    value={toLocation}
                    handleChange={(values) => setLocationValue(values)}
                    // handleChange={(values) => console.log(values, "values")}
                    isReadonly={isReadyOnly}
                    // isrequired={isrequired}
                    // errorValidation={errorValidation ?? {}}
                    disabled={
                      dataSecurityMappedCountryAndSelectedlevel?.country
                        ? true
                        : false
                    }
                  />
                )}

                {/* /////////////////////////// */}

                {statusData.status === 10 && sec() && (
                  <div style={{ marginTop: "12px", marginBottom: "12px" }}>
                    <Select
                      id={"dashboardDiseaseSelect"}
                      className="basic-single"
                      classNamePrefix="select"
                      // isLoading={props?.isAdmin ? props?.getAllDiseases?.loading : props?.getMappedDiseases?.loading}
                      menuPosition={"fixed"}
                      isClearable
                      isSearchable
                      value={reason}
                      placeholder="Select Reason"
                      options={initOptions}
                      onChange={(value) => changeState(value)}
                      // isDisabled={(props?.disabled || props?.isReadonly) ?? false}
                      styles={{
                        width: "50%",
                        // control: (provided, state) =>
                        // props?.errorValidation?.error
                        //   ? {
                        //     ...provided,
                        //     boxShadow: "0 0 0 0px red !important",
                        //     borderColor: "red !important"
                        //   }
                        //   : provided
                      }}
                    />
                  </div>
                )}

                {/* /////////////////////////// */}

                {statusData.status !== 10 && (
                  <TextField
                    id="archiveText"
                    autoFocus
                    onChange={(e) => onChangeInput(e)}
                    value={state.archivedText}
                    fullWidth
                    name="archivedText"
                    label={getLabel()}
                    onKeyDown={blockInvalidChar}
                    margin="dense"
                    error={state.error.archivedText}
                    helperText={
                      state.error.archivedText === true
                        ? Message.requiredMessage
                        : ""
                    }
                  />
                )}

                {statusData.status === 10 &&
                  sec() &&
                  reason?.value === "Others" && (
                    <TextField
                      id="archiveText"
                      autoFocus
                      onChange={(e) => onChangeInput(e)}
                      value={state.archivedText}
                      fullWidth
                      name="reject Reason"
                      onKeyDown={blockInvalidChar}
                      label={getLabelForReject()}
                      margin="dense"
                      error={state.error.archivedText}
                      helperText={
                        state.error.archivedText === true
                          ? Message.requiredMessage
                          : ""
                      }
                    />
                  )}
              </>
            }
            negativeButton
            negativeButtonText="Cancel"
            negativeButtonAction={cancelUpdateStatus}
            positiveButton
            positiveButtonText={
              statusData.status === 4
                ? "Confirm Archived"
                : statusData.status === 10
                ? "Confirm Reject"
                : "Confirm Transfered"
            }
            positiveButtonAction={confirmUpdateStatus}
          />
        }

        {showDivider && (
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
        )}

        {showInfoButton && (
          <Grid item>
            <IconButton
              id="infoBTN"
              onClick={onActionIconBtnClicked}
              className={classes.info2}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
