import React, { useState, useEffect } from "react";
import { ManageCard } from "atp-crud";
import { useDispatch, useSelector } from "react-redux";
import { actions, apis } from "hcc-binder";
import { withNavBars } from "../../HOCs";
import { withRouter } from "react-router-dom";
import {
  Drawer, Avatar
} from "@material-ui/core";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  useTheme,
  TextField,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../router/routes";
import FormRendererParent from "../formRenderer";
import { makeStyles } from "@material-ui/core/styles";
// import FormRenCheck from "./formRenCheck";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormRenCons from "./formrencons";
import { AlertContext, DialogContext, BackdropContext } from "../../contexts";
import { AlertProps } from "../../utils";
import Button from '@mui/material/Button';



const useStyles = makeStyles((theme) => ({
  dialogHeading: {
    fontFamily: "Poppins",
    fontWeight: "SemiBold",
    color: theme?.palette?.primary?.main,
    fontSize: "17px",
    textTransform: "uppercase",
    display: "grid",
    gridTemplateColumns: "50% 50%"
  },
  avatarDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
  },
  avatar: {
    width: "20px",
    height: "20px",
    borderRadius: "20%",
  },
  divider: {
    width: "20%",
  }
}));


export const ManageConsumer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const FormId = "67e4848f-d031-43fd-aeaa-3dde66785eaf"

  const [open, setOpen] = React.useState(false);
  const [editProvId, setEditProvId] = React.useState("");
  const [editEntryId, setEditEntryId] = React.useState("");
  const [contentState, setContentState] = React.useState([]);
  const backDrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const dialogContext = React.useContext(DialogContext);
  const [onDelete, setOnDelete] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditProvId("");
    setEditEntryId("");
    setOpen(false);
  };

  useEffect(() => {
    debugger;
    dispatch(actions.GET_ALL_CONSUMER());
    dispatch(actions.getallcontents());
  }, [open, onDelete]);



  const consumerData = useSelector((state) => ({
    getallconsumer: state?.renderSlice?.getconsumer?.data?.result,
  }));

  const contentData = useSelector((state) => ({
    getallcontents: state?.providerSlice?.getcontents?.data?.result,
  }));





  const addConsumer = (props) => {
    handleClickOpen();

  };

  const editfunc = (value) => {
    console.log("EditValue", value)
    setEditProvId(value?.currentmapobject?.Consumer_Id);
    setEditEntryId(value?.currentmapobject?.entry_id);

    setOpen(true);
  };

  const onDeleteClicked = (value) => {
    console.log("DeleteValue", value);
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "are you sure you want to delete this consumer?",
      body: "If you delete this Consumer all the Contents that tagged to this Consumer will be deleted.",
      positiveBtn: "I'm aware delete",
      negativeBtn: "Cancel",
      onOk: () => deleteConsumer(value.currentmapobject),
    });

  }

  const deleteConsumer = (value) => {
    debugger;
    dialogContext.setDialog({ ...dialogContext, open: false });
    backDrop.setBackDrop({
      ...backDrop,
      open: true,
      message: "Deleting Consumer...",
    });

    Promise.resolve(
      dispatch(
        actions.DELETE_CONSUMER({
          _key: value._key
        })
      )
    ).then((res) => {
      backDrop.setBackDrop({ ...backDrop, open: false });
      let resStatus = res.payload.error;
      setOnDelete(!onDelete);
      console.log("resStatus", resStatus)

      alert.setSnack({
        ...alert,
        open: true,
        severity: resStatus
          ? AlertProps.severity.error
          : AlertProps.severity.success,
        msg: resStatus
          ? "Consumer deletion Failed !"
          : "Consumer deleted Successfully !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    })

  }

  const handlelclick = (value) => {
    setContentState([...value?.Content_Id])
    console.log(value);
    toContent(value);
  };

  const toContent = (value) => {
    history.push({
      pathname: "/contentmapping",
      state: { value: value, contentData: contentData.getallcontents, content_Id: value?.Content_Id },
    });
  }





  return (
    // <FormRenCheck />
    // <FormRendererParent
    //   id="c3c176b9-5369-4a71-b0c6-bfa043ad8994"
    //   secToShow={[0, null]}
    //   secName={0}
    // />
    <div>
      <ManageCard
        //Below props for SearchFilter Props
        title="Manage Consumer"
        topbuttonName={"Add Consumer"}
        hideFilter={true}
        showSort={false}
        addfunc={addConsumer}
        editfunc={editfunc}
        theme={theme}
        /* addfunc={clientData === "" ? addForm : null}
          sortOptions={sortOptionArray}
          sortfunc={handleSortChange}
          searchfunc={onSearchChange}
          inbuiltsearchfunctinality={false}
          */
        //below props for Card List
        basedata={consumerData.getallconsumer}
        keysformap={{
          title_key: "Consumer Name",
          // cardname_key: "form_type.form_type_name",
        }}
        // hidebtn1={false}
        // Btn1Name={"Copy Id"}
        // //onBtn1Clicked={setCopy}
        // hidebtn2={false}
        // Btn2Name={"View Form"}
        isMoreBtn={true}
        hideEditBtn={false}
        hideDeleteBtn={false}
        deletefunc={onDeleteClicked}
        isonclick={true}
        onclick={handlelclick}
      /* onBtn2Clicked={onViewFormClicked}
      
        hideEditBtn={clientData === "" ? false : true}
        hideDeleteBtn={clientData === "" ? false : true}
        editfunc={onEditIconClicked}
        deletefunc={onDeleteIconClicked} */
      />
      <Dialog maxWidth="lg" PaperProps={{ sx: { width: "70%" } }} open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogHeading}>

          <h3>  Consumer Details</h3>
          <div className={classes.avatarDiv}>
            <Avatar
              src="/images/close.svg"
              className={classes.avatar}
              // className={`${state.direction === 'left' ? `${classes.left_large}` : `${classes.large}`} ${state?.isLarge ? classes.large_extra_large : ""}`}
              onClick={handleClose} />
          </div>

        </DialogTitle>
        <Divider />
        <DialogContent>
          <FormRenCons
            FormId={FormId}
            Consumer_Id={editProvId}
            entryId={editEntryId}
            close={handleClose}
            contentState={contentState}
            contentData={contentData.getallcontents}
            setContentState={setContentState}

          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(withNavBars(ManageConsumer));
