import React from "react";
import ReactDOM from "react-dom";

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "hcc-binder";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mappingGrid: {
    marginTop: "20px",
    marginBottom: "15px",
    border: "1px solid rgb(220 220 220)",
    padding: "10px",
    height: "200px",
    width: "600px",
    borderRadius: "20px",
  },
  parentmapGrid: {
    padding: "10px 0px",
    //backgroundColor: "green",
    marginLeft: "20px",
  },
}));

function ContentMapppings() {
  const location = useLocation();
  const classes = useStyles();
  const [options, setOptions] = React.useState();
  const [contentState, setContentState] = React.useState();
  const [effState, setEffState] = React.useState(true);
  const dispatch = useDispatch();
  const backDrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const FormId = "67e4848f-d031-43fd-aeaa-3dde66785eaf";

  React.useEffect(() => {
    let conData = [];
    for (let i = 0; i < location.state?.contentData?.length; i++) {
      let con = {
        label: location.state?.contentData[i].Content_Name,
        value: location.state?.contentData[i].Content_ID,
      };
      let inId = false;
      if (location.state.content_Id?.length > 0) {
        for (let j = 0; j < location.state.content_Id?.length; j++) {
          if (
            location.state.content_Id[j] ===
            location.state?.contentData[i].Content_ID
          ) {
            inId = true;
          }
        }
      }

      con.checked = inId;
      conData.push(con);
    }
    setOptions(conData);
  }, []);

  const onSave = (data) => {
    debugger;
    let cIds = [];
    let contentIds = options?.map((id) => {
      if (id.checked === true) {
        cIds.push(id.value);
      }
    });

    console.log("idss", cIds);

    let params = {
      Consumer_Id: location.state?.value?.Consumer_Id,
      Content_Id: cIds,
    };

    Promise.resolve(dispatch(actions.CONTENT_MAPPING(params))).then((res) => {
      backDrop.setBackDrop({
        ...backDrop,
        open: false,
      });

      if (!res.payload.error) {
        //   if (!data.isDraft && !isEdit) {
        //     setIsChangesSaved(true);
        //   }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `Content  has been ${
            data.isDraft
              ? "saved as draft"
              : location.state?.value?.entry_id
              ? "Mapped"
              : "Mapped"
          } Successfully!!!`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong! Try Again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    });
  };

  const handleChange = (event, content) => {
    console.log("eeee", event.target.checked);
    console.log("content", content);

    let newOptions = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].value === content.value) {
        options[i].checked = event.target.checked;
        newOptions.push(options[i]);
      } else {
        newOptions.push(options[i]);
      }
    }

    setOptions(newOptions);
    // let filData = options.filter((op) => op.value !== content.value);
    // content.checked = event.target.checked;
    // console.log("Aftercontent", content);
    // setOptions([...filData, { ...content }]);

    // setOptions([{ ...options, {[content.checked]: event.target.checked }]);
  };

  return (
    <div>
      <Grid container direction="row" className={classes.parentmapGrid}>
        <FormControl fullWidth component="fieldset" margin="normal">
          <FormLabel component="legend" focused={false}>
            Select Content
          </FormLabel>
          <Grid item className={classes.mappingGrid}>
            <FormGroup aria-label="Temas" row={true}>
              {options?.map((content, i) => (
                <FormControlLabel
                  key={content.value}
                  control={
                    <Checkbox
                      checked={content.checked}
                      onChange={(e) => handleChange(e, content)}
                    />
                    /* <input
                    type="checkbox"
                    name={`nativeThemes[${i}]`}
                    value={content.label}
                    //ref={register}
                  /> */
                  }
                  label={content.label}
                />
              ))}
            </FormGroup>
          </Grid>
        </FormControl>

        <Box>
          <Button
            onClick={onSave}
            type="submit"
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </div>
  );
}

export default ContentMapppings;
