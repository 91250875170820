/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Different routes and their corresponding component are defined here.
 */

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";

import {
  NotFound,
  Login,
  Dashboard,
  AccessManagement,
  Generalmaster,
  Swagger,
  ManageConsumer,
  AddConsumer,
  FormRenderer,
  ContentMapping,
} from "./../screens";

import ManageProvider from "../screens/manageprovider";
import VerticalLinearStepper from "../screens/manageprovider/stepper";
import ProviderSteps from "../components/manageprovider/providersteps";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path={Routes.home} component={Login} />
        <Route path={Routes.login} component={Login} />
        <Route path={Routes.dashboard} component={Dashboard} />
        <Route path={Routes.AccessManagement} component={AccessManagement} />
        <Route path={Routes.GeneralMaster} component={Generalmaster} />
        <Route path={Routes.Swagger} component={Swagger} />
        <Route path={Routes.manageprovider} component={ManageProvider} />
        <Route
          path={Routes.createmanageprovider}
          component={VerticalLinearStepper}
        />
        <Route path={Routes.providersteps} component={ProviderSteps} />
        <Route path={Routes.ManageConsumer} component={ManageConsumer} />
        <Route path={Routes.CreateConsumer} component={AddConsumer} />
        <Route path={Routes.FormRenderer} component={FormRenderer} />
        <Route path={Routes.ContentMapping} component={ContentMapping} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
