import React,{useState} from 'react';
import {
    Drawer,Avatar 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        minWidth: 400,
        background: "#fff",
        [theme.breakpoints.down("xs")]: {
            maxWidth: 300,
            minWidth: 300,
        },
        overflowX: 'hidden'
    },
    large: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        position: "fixed",
        right: "410px",
        cursor: "pointer",
        top: 10,
        "& img": {
            objectFit: "scale-down",
            backgroundColor: "#fff"
        },
        [theme.breakpoints.down("xs")]: {
            right: "313px",

        },
    },
    root_extra_large: {
        maxWidth: 1000,
        minWidth: 1000,
        background: "#fff",
        [theme.breakpoints.down("xs")]: {
            maxWidth: 300,
            minWidth: 300,
        },
        overflowX: 'hidden'
    },
    large_extra_large: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        position: "fixed",
        right: "1010px",
        cursor: "pointer",
        top: 10,
        "& img": {
            objectFit: "scale-down",
            backgroundColor: "#fff"
        },
        [theme.breakpoints.down("xs")]: {
            right: "313px",

        },
    },
    left_large: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        position: "fixed",
        left: "410px",
        cursor: "pointer",
        top: 10,
        "& img": {
            objectFit: "scale-down",
            backgroundColor: "#fff"
        },
        [theme.breakpoints.down("xs")]: {
            right: "313px",
            left: "310px",

        },
    }
}));

export const DrawerComponent = (props) => {

    const classes = useStyles(props);

    const [state,setState] = useState({
        open: props.open ?? false,
        direction:props?.direction ?? 'right',
        variant:props?.variant ?? 'temporary',
        isLarge: props?.isLarge ?? false,
    })


    const close = () => {
        setState({
            ...state,
            open: false,
            isLarge: null,            
        });
        props.onClose && props.onClose();
    };

    React.useEffect(()=>{
        setState({
            ...state,open:props.open ?? false
        })
        // eslint-disable-next-line
    },[props.open])

    return (
        <div>
             <Drawer anchor={state.direction} variant={state.variant} open={state.open} onClose={close}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <Avatar
                        src="/images/close.svg"
                        className={`${state.direction === 'left' ? `${classes.left_large}` : `${classes.large}`} ${state?.isLarge ? classes.large_extra_large : ""}`}
                        onClick={close} />

                    <div className={`${classes.root} ${state?.isLarge ? classes.root_extra_large : ""}`}>{props?.children}</div>
                </Drawer>
        </div>
    )
}

