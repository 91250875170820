import {
  Button,
  Checkbox,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Mobile,DatePicker,TimePicker,DateTimePicker,Input } from '../../../components';
// import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-head": {
      backgroundColor: "#E5EAF4",
      minWidth: 150,
    },
  },
  addColumn: {
    "& .MuiIconButton-root": {
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  addRowBtn: {
    minWidth: 150,
  },
  errorText:{
    color:'red',
    fontSize:'0.75rem'
  }
}));
const CustomTableFixedRowFormRender = (props) => {
  let initialArray = [];

  const classes = useStyles();

  const [tableData, setTableData] = React.useState(
    props?.tableData?.map((_) => _) ?? initialArray
  );


  const initialArrayFunc = () => {
    if (props?.data?.table_scheme?.row?.length < 0) {
      return [];
    } else {
      props?.data?.table_scheme?.row?.map((l,i) => {
        initialArray[i]=[];
        initialArray[i][0]=l.name;
        return l
      });
      props?.handleChange && props?.handleChange({
        tableData:initialArray
      });
      return initialArray;
    }
  };


  React.useEffect(() => {
    props?.data?.table_scheme?.row?.map((rowData, rowIndex) => {
      props?.data?.table_scheme?.column?.map((colData, columnIndex) => {
        let checkboxId = document.getElementById(
          `${props?.data?.id}-checkbox-${rowIndex}-${columnIndex}`
        );
        let inputId = document.getElementById(
          `${props?.data?.id}-input-${rowIndex}-${columnIndex}`
        );
        let dateId = document.getElementById(
          `${props?.data?.id}-date-${rowIndex}-${columnIndex}`
        );
        let dropdownId = document.getElementById(
          `${props?.data?.id}-dropdown-${rowIndex}-${columnIndex}`
        );
        let mobileId = document.getElementById(
          `${props?.data?.id}-mobile-${rowIndex}-${columnIndex}`
        );
        let timeId = document.getElementById(
          `${props?.data?.id}-time-${rowIndex}-${columnIndex}`
        );
        let dateTimeId = document.getElementById(
          `${props?.data?.id}-dateTime-${rowIndex}-${columnIndex}`
        );
        let numberId = document.getElementById(
          `${props?.data?.id}-number-${rowIndex}-${columnIndex}`
        );
        if (checkboxId) {
          checkboxId.value = "";
        }
        if (inputId) {
          inputId.value = "";
        }
        if (dateId) {
          dateId.value = "";
        }
        if (dropdownId) {
          dropdownId.value = "";
        }
        if (mobileId) {
          mobileId.value = "";
        }
        if (timeId) {
          timeId.value = "";
        }
        if (dateTimeId) {
          dateTimeId.value = "";
        }
        if (numberId) {
          numberId.value = "";
        }
        return colData;
      });
      return rowData;
    });
    setTableData(props?.tableData?.map((_) => _) ?? initialArrayFunc());
    // eslint-disable-next-line
  }, [props?.tableData]);

  const onChangeValue = (value, rowIndex, columnIndex) => {
    // ;
    let data = tableData?.[rowIndex]?.map((_) => _) ?? [];
    data[columnIndex] = value;
    tableData[rowIndex] = data;
    setTableData(tableData);
    props?.handleChange({
      tableData,
      // table_scheme: props?.data?.table_scheme,
    });
  };

  const addRow = () => {
    tableData.push(["Row Type"]);
    props?.handleChange({
      tableData,
      // table_scheme: props?.data?.table_scheme
    });
  };

  const deleteRow = (index) => {
    let filteredData = tableData.filter((l, i) => i !== index);
    setTableData(filteredData);

    props?.handleChange({
      tableData: filteredData,
    });
  };

  const tableRows = () => {
    let tableDataLength = props?.tableData?.length ?? 0;

    if (props?.data?.table_scheme?.row?.length >= tableDataLength) {
      return props?.data?.table_scheme?.row;
    } else {
      return props?.tableData ?? [];
    }
  };


  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        {props.heading && (
          <Typography id={"heading"+props?.id} variant="body1" gutterBottom>
            {props.heading}
          </Typography>
        )}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </div>

      <TableContainer id={"TableContainer2"+props?.id}>
        <Table aria-label="simple table" id={"Table2"+props?.id}>
          <TableHead id={"TableHead2"+props?.id}>
            <TableRow id={"TableRow2"+props?.id}>
              <TableCell id={"TableCELL2"+props?.id} variant="head" style={{ width: 20, minWidth: 20 }}>
                <Typography variant="body1">Actions</Typography>
              </TableCell>
              {props?.data?.table_scheme?.column &&
                props?.data?.table_scheme?.column?.map((colData, index) => {
                  return (
                    <TableCell key={index} variant="head" id={"TableCELL2"+index}>
                      <Typography variant="body1">{colData.name}</Typography>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows()?.map((rowData, rowIndex) => {
              // 
              return (
                <TableRow key={rowIndex} id={"TableRow2"+rowIndex}>
                   <TableCell  id={"TableCELL2"+rowIndex}>
                 {
                     rowIndex >= props?.data?.table_scheme?.row?.length  ? (
                    <IconButton id={"DELETEBTN"+rowIndex} onClick={() => deleteRow(rowIndex)}>
                      <DeleteOutlineIcon />
                    </IconButton>) : ''
                  }
                  </TableCell>
                  {props?.data?.table_scheme?.column &&
                    props?.data?.table_scheme?.column?.map(
                      (colData, columnIndex) => {
                        switch (colData.ui_component) {
                          case "check_box":
                            return (
                              <TableCell key={columnIndex} id={"TableCELL3"+columnIndex}>
                                <Checkbox
                                  id={`${props?.data?.id}-checkbox-${rowIndex}-${columnIndex}`}
                                  checked={tableData?.[rowIndex]?.[columnIndex]}
                                  onChange={(event) =>
                                    onChangeValue(
                                      event.target.checked,
                                      rowIndex,
                                      columnIndex
                                    )
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  disabled={(props?.isReadonly || props?.disabled) ?? false}
                                />
                              </TableCell>
                            );
                          case "input_text":
                            return (
                              <TableCell id={"TableCELL3"+columnIndex}>
                                <TextField
                                  id={`${props?.data?.id}-input-${rowIndex}-${columnIndex}`}
                                  value={tableData?.[rowIndex]?.[columnIndex]}
                                  onChange={(event) =>
                                    onChangeValue(
                                      event.target.value,
                                      rowIndex,
                                      columnIndex
                                    )
                                  }
                                  inputProps={{
                                    readOnly: props.isReadonly ?? false,
                                  }}
                                  disabled={props?.disabled ?? false}
                                  error={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.error ? true : false}
                                  helperText={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage ? props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage : ''}
                                />
                              </TableCell>
                            );
                            case "input_number":
                              return (
                                <TableCell id={"tableCELLQ"+columnIndex}>
                                   <Input
                                        id={`${props?.data?.id}-number-${rowIndex}-${columnIndex}`}
                                        type={"number"}
                                        value={tableData?.[rowIndex]?.[columnIndex]}
                                        onChange={(value) =>  onChangeValue(
                                          value,
                                          rowIndex,
                                          columnIndex
                                        )}
                                        label=""
                                        isReadonly={props?.isReadyOnly}
                                        decimalNo={rowData?.validations?.[columnIndex]?.decimalNo}
                                        disabled={props?.disabled ?? false}
                                    />
                                </TableCell>
                            );
                            case "date":
                              return (
                                <TableCell id={"tableCELLQ"+columnIndex}>
                                  <div style={{minWidth:250}}>
                                      <DatePicker
                                        id={`${props?.data?.id}-date-${rowIndex}-${columnIndex}`}
                                        dateFormat={rowData?.validations?.[columnIndex]?.dateFormats?.value}
                                        // value={value?.length > 0 ? value : data?.validation?.currentDate}
                                        value={tableData?.[rowIndex]?.[columnIndex]}
                                        // onChange={(value) => onChange(index, data, value)}
                                        onChange={(value) =>  onChangeValue(
                                          value,
                                          rowIndex,
                                          columnIndex
                                        )}
                                        isReadonly={props?.isReadyOnly ?? false}
                                        // data={data}
                                        disabled={props?.disabled ?? false}
                                        validation={rowData?.validations}
                                        fromDate={rowData?.validations?.[columnIndex]?.fromDate}
                                        toDate={rowData?.validations?.[columnIndex]?.toDate}
                                        currentDate={rowData?.validations?.[columnIndex]?.currentDate}
                                        toggleSelectedData={rowData?.validations?.[columnIndex]?.toggleSelectedData}
                                        />
                                  </div>
                                </TableCell>
                              );
                            case "time":
                              return (
                                <TableCell id={"tableCELLQ"+columnIndex}>
                                  <div style={{minWidth:250}}>
                                      <TimePicker
                                        id={`${props?.data?.id}-time-${rowIndex}-${columnIndex}`}
                                        timeFormat={rowData?.validations?.[columnIndex]?.timeFormats?.value}
                                        value={tableData?.[rowIndex]?.[columnIndex]}
                                        onChange={(value) =>  onChangeValue(
                                          value,
                                          rowIndex,
                                          columnIndex
                                        )}
                                        isReadonly={props?.isReadyOnly ?? false}
                                        disabled={props?.disabled ?? false}
                                        />
                                  </div>
                                </TableCell>
                              );
                            case "date_time":
                              return (
                                <TableCell id={"tableCELLQ"+columnIndex}>
                                  <div style={{minWidth:250}}>
                                      <DateTimePicker
                                        id={`${props?.data?.id}-dateTime-${rowIndex}-${columnIndex}`}
                                        dateTimeFormat={rowData?.validations?.[columnIndex]?.dateTimeFormats?.value}
                                        value={tableData?.[rowIndex]?.[columnIndex]}
                                        onChange={(value) =>  onChangeValue(
                                          value,
                                          rowIndex,
                                          columnIndex
                                        )}
                                        isReadonly={props?.isReadyOnly ?? false}
                                        disabled={props?.disabled ?? false}
                                        />
                                  </div>
                                </TableCell>
                              );
                          case "dropdown":
                            return (
                              <TableCell id={"TableCELL3"+columnIndex}>
                                <Autocomplete
                                  id={`${props?.data?.id}-dropdown-${rowIndex}-${columnIndex}`}
                                  options={colData?.options ?? []}
                                  getOptionLabel={(option) => option}
                                  // style={{ width: 300 }}
                                  onChange={(e, newValue) =>
                                    onChangeValue(
                                      newValue,
                                      rowIndex,
                                      columnIndex
                                    )
                                  }
                                  disabled={(props.isReadonly || props?.disabled) ?? false}
                                  value={tableData?.[rowIndex]?.[columnIndex]}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </TableCell>
                            );
                            case "mobile":
                              return (
                                <TableCell id={"TableCELL3"+columnIndex}>
                                    <Mobile
                                      id={"tableType2"+columnIndex}
                                      value={tableData?.[rowIndex]?.[columnIndex] ?? {}}
                                      handleChange={(value) => onChangeValue(value,rowIndex,columnIndex)}
                                      type={"number"}
                                      // isReadonly={isReadyOnly}
                                      // isrequired={isrequired}
                                      data={{
                                        id:`${props?.data?.id}-mobile-${rowIndex}-${columnIndex}`
                                      }}
                                      isReadonly={props?.isReadonly ?? false}
                                      disabled={props?.disabled ?? false}
                                      // errorValidation={errorValidation ?? {}}  
                                      />
                                </TableCell>
                              );
                          default:
                            if (columnIndex === 0) {
                              return (
                                <TableCell key={columnIndex} id={"TableCELL3"+columnIndex}>
                                  <Typography variant="body1">
                                    {/* {tableData?.[rowIndex]?.[columnIndex]} */}
                                    {rowData?.name}
                                    {/* {tableData?.[rowIndex]?.[columnIndex]} */}
                                  </Typography>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={columnIndex} id={"TableCELL3"+columnIndex}>
                                  <TextField
                                    id={`${props?.data?.id}-input-${rowIndex}-${columnIndex}`}
                                    value={tableData?.[rowIndex]?.[columnIndex]}
                                    onChange={(event) =>
                                      onChangeValue(
                                        event.target.value,
                                        rowIndex,
                                        columnIndex
                                      )
                                    }
                                    inputProps={{
                                      readOnly: props.isReadonly ?? false,
                                    }}
                                    disabled={props?.disabled ?? false}
                                    error={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.error ? true : false}
                                    helperText={props?.errorValidation?.length > 0 && props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage ? props?.errorValidation?.[rowIndex]?.[columnIndex]?.errorMessage : ''}
                                  />
                                </TableCell>
                              );
                            }
                        }
                      }
                    )}
                </TableRow>
              );
            })}
            <Button
              id="ADDROW"
              variant="text"
              color="secondary"
              className={classes.addRowBtn}
              onClick={addRow}
              disabled={(props.isReadonly || props?.disabled) ?? false}
            >
              <Typography>Add Row</Typography>
              <IconButton>
                <AddCircleOutlineOutlinedIcon color="secondary" />
              </IconButton>
            </Button>
          </TableBody>
        </Table>
      </TableContainer>
      {/* {
          props?.errorValidation && props?.errorValidation?.error && 
          <div><Typography variant="caption" className={classes.errorText}>{props?.errorValidation?.errorMessage}</Typography></div>
      } */}
    </div>
  );
};
//  CustomTableFixedRowFormRender.propTypes = {

//  };
export default CustomTableFixedRowFormRender;
