/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all the message used in the application.
 */

//Normal messagess
export let Message = {
  requiredMessage: "This field is required",

  // content messages
  addcontent: "Adding contents...",
  deletecontent: "Deleting content...",
  updatingcontent: "Updating contents...",

  delete_provider_title: "Are you sure you want to delete Provider?",
  delete_provider_message: "",
  delete_provider_btn: "I'm aware delete",

  delete_provider_success: "Provider deleted Successfully",
  add_provider_success: "Provider created successfully",
  add_content_success: "Content created successfully",
  updated_content_success: "Content updated successfully",
  delete_content_success: "Content deleted successfully",
};
