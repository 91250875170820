import { Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";

function BasicDetails(props) {
  let { state, handleChange } = props;

  return (
    <div>
      <Grid container style={{ padding: "15px 10px" }} spacing="2" md={12}>
        <Grid item md={3}>
          <TextField
            variant="outlined"
            label="First Name"
            value={state.firstName}
            size="small"
            onChange={(e) => handleChange("firstName", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            variant="outlined"
            label="Last Name"
            value={state.lastName}
            size="small"
            onChange={(e) => handleChange("lastName", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            variant="outlined"
            label="Email"
            value={state.email}
            size="small"
            onChange={(e) => handleChange("email", e.target.value)}
            fullWidth
            type={"email"}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            variant="outlined"
            label="Phone Number"
            value={state.phoneNumber}
            size="small"
            onChange={(e) => handleChange("phoneNumber", e.target.value)}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            variant="outlined"
            label="Country"
            value={state.country}
            size="small"
            onChange={(e) => handleChange("country", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            variant="outlined"
            label="State"
            value={state.state}
            size="small"
            onChange={(e) => handleChange("state", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            variant="outlined"
            label="City"
            value={state.city}
            size="small"
            onChange={(e) => handleChange("city", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            variant="outlined"
            label="Pin Code"
            value={state.pincode}
            size="small"
            onChange={(e) => handleChange("pincode", e.target.value)}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            variant="outlined"
            label="Address"
            value={state.address}
            size="small"
            onChange={(e) => handleChange("address", e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default BasicDetails;
