/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

import React from "react";
import { LinearProgress } from '@mui/material';
import { NetworkCall } from "./networkcall";
import { initialMessageCatlog } from "atp-multilingual";
import {VaultRequest} from "vault-request";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intialLoad: true
    };
  }

  async componentDidMount() {
    NetworkCall('meta.json', 'GET', null, null, false, true).then((_) => {
      localStorage.setItem('version', _.data.version)
    }).catch(err => {
      console.log('err:', err);
    })
    await initialMessageCatlog("METADATA_ID");
    this.setState({ intialLoad: false })

    this.GetToken();
  }

  GetToken = async () => {
    const data = await VaultRequest({token:process.env.REACT_APP_VAULT_TOKEN});
    localStorage.setItem(
      "EnvironmentData",
      btoa(
          JSON.stringify(data?.data?.data?.data)
      ))
  }

  render() {

    let { loading } = this.props;
    let { intialLoad } = this.state;

    return (
      <>
        {(loading || intialLoad) && (
          <LinearProgress />
        )}
        {!intialLoad && this.props.children}
      </>
    );
  }
}

export default AppAuth;
