import React, { useContext } from "react";
import {
  Button,
  makeStyles,
  Divider,
  Typography,
  Grid,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "../select";
import { AlertProps, Message } from "../../utils";
import { useDispatch } from "react-redux";
import { AlertContext, BackdropContext, DrawerContext } from "../../contexts";
import { v4 as uuidV4, v4 } from "uuid";
import { actions, apis } from "hcc-binder";
import {
  AddCollection,
  AddDatabase,
  AddProject1,
  AddProjectconfig,
} from "./apisofaddcontent";
import { envdata } from "../../utils/helperFunctions";
let env = envdata();

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    padding: "10px 20px",
    color: theme.palette.primary.main,
  },
  countryParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  countryCodeParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btn: {
    alignItems: "center",
    // justifyContent:'center'
  },
}));

function Addcontent(props) {
  console.log({ props });
  const editcontentypeobjcontrcutor = (data = [], id) => {
    // debugger;
    let filter = data?.filter((v) => {
      return v?.coding?.[0]?._id === id;
    });
    let result = valuelabelfinder(filter);
    return result?.[0];
  };

  const valuelabelfinder = (data) => {
    try {
      const result = data.map((v) => {
        let obj = {
          label: v?.coding?.[0]?.display,
          value: v?.coding?.[0]?.code,
          _id: v?.coding?.[0]?._id,
        };
        return obj;
      });
      return result;
    } catch (err) {
      return [];
    }
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const alertContext = React.useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const drawer = useContext(DrawerContext);
  const [state, setState] = React.useState({
    contentName: props?.editData?.Content_Name ?? "",
    contentType: editcontentypeobjcontrcutor(
      props.contenttype,
      props?.editData?.Content_Type
    ),
    storage: editcontentypeobjcontrcutor(
      props.storage,
      props?.editData?.Storage
    ),
    description: props?.editData?.Description ?? "",
    uploadurl: props?.editData?.UploadUrl ?? "",
    contentId: v4(),
    errorValidation: {
      error: {
        contentName: false,
        contentType: false,
        storage: false,
        description: false,
        uploadurl: false,
      },
    },
  });
  const validation = () => {
    debugger;
    let isValidcontentName =
      state?.contentName?.trim().length === 0 ? false : true;
    let isValidcontentType = Boolean(state?.contentType?.value);
    let isvalidstorage = Boolean(state?.storage?.value);
    let isvaliddescription = state?.description?.length === 0 ? false : true;
    let isvaliduploadurl = state?.uploadurl?.length === 0 ? false : true;
    if (
      isValidcontentName &&
      isValidcontentType &&
      isvalidstorage &&
      isvaliddescription &&
      isvaliduploadurl
    ) {
      return true;
    } else {
      state.errorValidation.error = {
        contentName: !isValidcontentName,
        contentType: !isValidcontentType,
        storage: !isvalidstorage,
        description: !isvaliddescription,
        uploadurl: !isvaliduploadurl,
      };
      setState({ ...state });
    }
  };
  const submitForm = async () => {
    debugger;
    const isValidated = validation();
    if (!isValidated) {
      return false;
    }

    let __params = {
      Content_ID: state.contentId,
      Content_Name: state.contentName,
      Content_Type: state.contentType?._id,
      Storage: state.storage?._id,
      Description: state.description,
      UploadUrl: state.uploadurl,
      activestatus: true,
      Provider_Id: props?.providerstate?.Provider_Id,
    };

    let filter = { filter: { _id: `${props?.editData?._id}` } };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: props.isEdit ? Message.updatingcontent : Message.addcontent,
    });
    let apidata = {
      dbname: env?.REACT_APP_DB,
      entity: "Content",
      ismeta: true,
      metadataid: env?.REACT_APP_METADATAID,
      metadatadbname: env?.REACT_APP_METADATA_DB_NAME,
      filter: props?.isEdit ? filter : undefined,
      doc: __params,
    };
    let addproject1payload = {
      client: props?.providerstate?.["Providers Name"],
      db_name: "undefined",
      projectname: state.contentName?.trim(),
      storagetype: state.storage?.value,
      description: state.description,
      uploadurl: state.uploadurl,
      metadataId: "",
      metadataname: state.contentName?.trim(),
      attributes: [
        {
          theme: "",
          user: "",
        },
      ],
      metadata: [],
      entity_relationship: [],
      clientId: props?.providerstate?.Provider_Id,
      clientname: props?.providerstate?.["Providers Name"],
      projectId: state.contentId,
    };
    let addDbPayload = {
      db_name: `${
        props?.providerstate?.["Providers Name"]
      }_${state.contentName?.trim()}`,
    };
    let addprojectconfig = {
      projectName: state.contentName,
      clientName: props?.providerstate?.["Providers Name"],
      dataStorage: `${
        props?.providerstate?.["Providers Name"]
      }_${state.contentName?.trim()}`,
      connectionParams: {
        ArangoUrl: state.uploadurl,
      },
      type: state.storage.value,
    };
    const contentupsert = new Promise((resolve, rejected) => {
      Promise.resolve(apis.UpsertandUpdate(apidata))
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          rejected(err);
          console.log(err);
        });
    });

    debugger;
    const addproject = await AddProject1(addproject1payload);
    // AddProject2();
    debugger;
    const AddDatabasee = await AddDatabase(addDbPayload);
    debugger;
    const AddCollections = await AddCollection(addDbPayload);
    debugger;
    const AddProjectconfigs = await AddProjectconfig(addprojectconfig);
    debugger;
    Promise.all([
      contentupsert,
      addproject,
      AddCollections,
      AddDatabasee,
      AddProjectconfigs,
    ]).then((values) => {
      console.log({ values });
      drawer.setDrawer({
        open: false,
      });
      alertContext.setSnack({
        ...alertContext,
        open: true,
        severity: AlertProps.severity.success,
        msg: props?.isEdit
          ? Message.updated_content_success
          : Message.add_content_success,
        horizontal: AlertProps.horizontal.right,
        vertical: AlertProps.vertical.top,
      });
      setState({
        ...state,
        contentName: "",
        contentType: {},
        storage: {},
        description: "",
        uploadurl: "",
        contentId: v4(),
        errorValidation: {
          error: {
            contentName: false,
            contentType: false,
            storage: false,
            description: false,
            uploadurl: false,
          },
        },
      });
    });
    dispatch(actions.getsinglecontent(props?.providerstate?.Provider_Id));

    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
    drawer.setDrawer({
      open: false,
    });
    // submitContent();
    // Promise.resolve(dispatch(AddDisease(apidata))).then((res) => {
    //   //debugger;
    //   backdrop.setBackDrop({ ...backdrop, open: false });
    //   props.onActionCompleted(props.isEdit);
    //   let resStatus = res.payload.error;
    //   let statusMessage = props.isEdit
    //     ? "Disease Updated Successfully !"
    //     : "Disease Added Successfully !";
    //   let statusMessage2 = props.isEdit
    //     ? "Disease Updated Failed!"
    //     : "Disease Adding Failed !";
    //   //debugger;
    //   if (res?.payload?.message === "Disease Already Exist !") {
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: resStatus
    //         ? AlertProps.severity.error
    //         : AlertProps.severity.success,
    //       msg: res?.payload?.message,
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   } else {
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: resStatus
    //         ? AlertProps.severity.error
    //         : AlertProps.severity.success,
    //       msg: resStatus ? statusMessage2 : statusMessage,
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.center,
    //     });
    //   }
    // });
  };
  const onChangeInput = (name, value) => {
    //debugger;
    setState({ ...state, [name]: value });
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography className={classes.header}>
            {props?.isEdit ? "Update Content" : "Add Content"}
          </Typography>
          <Divider />
        </Grid>

        <Grid item className={classes.countryParent}>
          <TextField
            onChange={(e) => onChangeInput("contentName", e.target.value)}
            name="content name"
            value={state?.contentName ?? ""}
            fullWidth
            label="Content Name"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={state.errorValidation.error.contentName}
            helperText={
              state.errorValidation.error.contentName === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item className={classes.countryParent}>
          <TextField
            onChange={(e) => onChangeInput("description", e.target.value)}
            value={state?.description ?? ""}
            fullWidth
            label="Description"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={state.errorValidation.error.description}
            helperText={
              state.errorValidation.error.description === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item className={classes.countryParent}>
          <TextField
            onChange={(e) => onChangeInput("uploadurl", e.target.value)}
            name="Upload Url"
            value={state?.uploadurl ?? ""}
            fullWidth
            label="Upload Url"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={state.errorValidation.error.uploadurl}
            helperText={
              state.errorValidation.error.uploadurl === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item className={classes.countryParent}>
          <Select
            name="ContentType"
            error={state?.errorValidation?.error?.contentType}
            helperText={
              state?.errorValidation?.error?.contentType === true
                ? Message?.requiredMessage
                : ""
            }
            label={"Content Type"}
            id="managediseaseClassification"
            value={state?.contentType ? [state?.contentType] : []}
            handleselect={(data) => onChangeInput("contentType", data)}
            option={valuelabelfinder(props?.contenttype)}
          />
        </Grid>
        <Grid item className={classes.countryParent}>
          <Select
            name="Storage"
            error={state?.errorValidation?.error?.storage}
            helperText={
              state?.errorValidation?.error?.storage === true
                ? Message?.requiredMessage
                : ""
            }
            label={"storage"}
            id="managediseaseClassification"
            value={state?.storage ? [state?.storage] : []}
            handleselect={(data) => onChangeInput("storage", data)}
            option={valuelabelfinder(props?.storage)}
          />
        </Grid>
        <Grid item className={classes.btnParent}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            // disabled={upsertDisease?.loading}
            onClick={() => submitForm()}
            id="addAndUpdateDiseaseButton"
          >
            {props?.isEdit ? "Update" : "Add "}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Addcontent;
