import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { TbLogout } from "react-icons/tb";
import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Tooltip,
  Divider,
  Avatar,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme?.zIndex?.drawer + 1,
  },
  appBar: {
    zIndex: theme?.zIndex?.drawer + 1,
    background: theme?.palette?.appBar?.main,
    boxShadow: "unset",
    // borderBottom: "1px solid #e0e0e0",
  },
  title: {
    display: "block",

    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  caption: {
    display: "block",
    marginRight: theme?.spacing(1),
    [theme?.breakpoints?.down("sm")]: {
      fontSize: "0.6rem !important",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme?.spacing(1),
    marginRight: theme?.spacing(1),
  },
  profilePic: {
    marginLeft: "8px",
  },
  notificationdiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notifications: {},
}));

export const TopNavBar = (props) => {
  const { user , role } = props;
  const classes = useStyles();
  const history = useHistory();
  const Theme = useTheme();
  const sm = useMediaQuery(Theme?.breakpoints?.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   React.useLayoutEffect(() => {
  //     getPersonLoggedin(
  //       localStorage.getItem("kclkid"),
  //       atob(localStorage.getItem("RBAC"))
  //     );
  //   }, []);

  //   const getPersonLoggedin = async (id, role) => {
  //     // debugger;
  //     let params = {
  //       db_name: Config.dbname,
  //       entity: env.REACT_APP_ENTITY_PERSON,
  //       filter: `${env.REACT_APP_ENTITY_PERSON}.keycloackid=='${id}' && ${env.REACT_APP_ENTITY_PERSON}.activestatus==true`,
  //       return_fields:
  //         role === "5"
  //           ? "{rolename:Person.roleid[0].rolename,person:MERGE(FOR Bed in Bed Filter Bed.BedNumber==document(Person.telecom[0]).value && Bed.activestatus==true RETURN MERGE(FOR PatientCheckIn in PatientCheckIn FILTER PatientCheckIn.BedCode==Bed._id && PatientCheckIn.activestatus==true  RETURN MERGE({text:FIRST(FOR Patient IN Patient FILTER Patient._id==PatientCheckIn.PatientCode && Patient.activestatus==true RETURN Patient.PatientFName)})))}"
  //           : "{rolename:Person.roleid[0].rolename,person:UNSET(document(Person.name[0]),'_key','_id','_rev','createddate','createdby','activestatus','updatedby','updatedate','family','id','given','prefix','use','person','period')}",
  //     };

  //     let res = await AxiosCall(
  //       "post",
  //       `${Config.api_url}/api/read_documents`,
  //       params
  //     );
  //     let result = res?.result[0];
  //     setUser(result);
  //   };

  const handleLogout = () => {
    localStorage.removeItem("queue_token");
    localStorage.removeItem("permissionData");
    localStorage.removeItem("keyclkId");
    localStorage.removeItem("RoleId");
    localStorage.removeItem("TriggerTiming");
    localStorage.removeItem("access_token");
    // localStorage.removeItem("RoleId")
    history.push("/");
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.titleContainer}>
          <img
              alt="icon"
              src="https://atp.dev.ainqaplatform.in/assets/01_AINQA_Logo_Horizontal_White.svg"
              style={{ width: "100px", height: "auto" }}
            />
            <div>
              <Typography className={classes.title} variant="body1" noWrap>
                {"HCC Platform"}
              </Typography>
              <Typography
                  // className={classes.title}
                  variant="caption"
                  noWrap
                >
                {process?.env?.REACT_APP_Version}
                </Typography>
            </div>
          </div>

          <div className={classes.grow} />
          
          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "16px 4px", width: "2px", background: "#fff" }}
          />
          
          <div className={classes.userProfilecard}>
            <div>
              <Typography
                className={classes.title}
                variant="body1"
                component={"h6"}
                noWrap
              >
                {user?.trim().length > 0 && user}
              </Typography>
              
             
                <Typography
                  // className={classes.title}
                  variant="caption"
                  noWrap
                >
                  {role}
                </Typography>
            
            </div>
          </div>
          <Avatar className={classes.profilePic} variant="square">
            
            {user?.split("")[0].toUpperCase()}
           
          </Avatar>
         

          {sm ? (
            <IconButton
              style={{ color: "#fff" }}
              onClick={() => handleLogout()}
            >
              <TbLogout />
            </IconButton>
          ) : (
            <Tooltip title="Logout" arrow>
              <IconButton
                style={{ color: "#fff" }}
                onClick={() => handleLogout()}
              >
                <TbLogout />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem Button onClick={() => handleLogout()}>
            <ListItemIcon style={{ width: "30px" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            {!sm && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
