/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-11-28
 * @desc Collection of constants
 */
import React from "react";
import { Routes } from "../router/routes";
// import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TableViewIcon from '@mui/icons-material/TableView';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AccessManagement from "@material-ui/icons/AccountBalanceTwoTone";
import GeneralMaster from "@material-ui/icons/DynamicFeedTwoTone";
import Dataset from '@mui/icons-material/Dataset';

import Dashboard from "@material-ui/icons/Dashboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Organization from "@material-ui/icons/ApartmentTwoTone";
import Forms from "@material-ui/icons/TableChartTwoTone";
import Dieases from "@material-ui/icons/LocalHospitalTwoTone";
import Notification from "@material-ui/icons/NotificationsActiveTwoTone";
import Verifictaion from "@material-ui/icons/DomainRounded";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export const crud = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
};

export const NavBarArr = [
  {
    name: "Dashboard",
    pageId: "bmy90",
    page: Routes.dashboard,
    repo: "Dashboard",
    masterLayoutId: "o4jsfz",
    size: 0,
    icon: <DashboardRoundedIcon color="primary" />,
    childPageSelect: [],
  },
  {
    name: "Manage Provider",
    pageId: "bmy90",
    repo: "Manage Provider",
    page: Routes.manageprovider,
    masterLayoutId: "o4jsfz",
    size: 0,
    icon: <DomainAddIcon color="primary" />,
    childPageSelect: [],
  },
  {
    name: "Manage Consumer",
    pageId: "bmy90",
    repo: "Manage Consumer",
    page: Routes.ManageConsumer,
    masterLayoutId: "o4jsfz",
    size: 0,
    icon: <AccountBalanceIcon color="primary" />,
    childPageSelect: [],
  },
  {
    name: "View Content",
    pageId: "bmy90",
    repo: "View Content",
    page: Routes.Swagger,
    masterLayoutId: "o4jsfz",
    size: 0,
    icon: <TableViewIcon color="primary" />,
    childPageSelect: [],
  },
  {
    name: "Access Management",
    pageId: "bmy90",
    page: Routes.AccessManagement,
    repo: "Access Management",
    masterLayoutId: "o4jsfz",
    size: 0,
    icon: <ManageAccountsIcon color="primary" />,
    childPageSelect: [],
  },
  {
    name: "General Master",
    pageId: "bmy90",
    repo: "General Master",
    page: Routes.GeneralMaster,
    masterLayoutId: "o4jsfz",
    size: 0,
    icon: <Dataset color="primary" />,
    childPageSelect: [],
  },
];

export const notifyTypeBesedOnEntryStatus = {
  notify: {
    1: "1",
    9: "2",
  },
  verify: {
    2: "6",
    3: "3",
    10: "7",
    11: "8",
  },
  register: {
    7: "9",
    8: "4",
    10: "10",
    11: "11",
  },
  investigate: {
    5: "12",
    6: "5",
  },
};

export const DependencyType = [
  {
    value: "form",
    label: "Form",
  },
  {
    value: "section",
    label: "Sections",
  },
  {
    value: "question",
    label: "Question",
  },
];

export const FormComponents = {
  single_tab_select: "single_tab_select",
  multi_tab_select: "multi_tab_select",
  input_text: "input_text",
  input_textarea: "input_textarea",
  input_date_picker: "input_date_picker",
  multi_select_tag: "multi_select_tag",
  custom_multi_select_tag: "custom_multi_select_tag",
  select_modal_dropdown: "select_modal_dropdown",
  list_text: "list_text",
  input_number: "input_number",
  hours_minutes_range: "hours_minutes_range",
  grade: "grade",
  image_picker: "image_picker",
  switch_tab: "switch_tab",
  range_picker: "range_picker",
  popup_switch: "popup_switch",
  label: "label",
  note: "note",
  check_box: "check_box",
  radio_button: "radio_button",
  location: "location",
  custom_table: "custom_table",
  custom_table_2: "custom_table_2",
  mobile_no: "mobile_no",
  location_level: "location_level",
  date_of_birth: "date_of_birth",
  file_upload: "file_upload",
  Image_upload: "Image_upload",
  Image_only_upload: "Image_only_upload",
  input_time_picker: "input_time_picker",
  input_datetime_picker: "input_datetime_picker",
};

export const subNavBarArr = [
  {
    id: "disease_masters",
    prioriy: 2,
    name: "Manage Disease",
    url: Routes.list_diseases,
  },
  {
    id: "manage_forms",
    prioriy: 3,
    name: "Manage Forms",
    url: Routes.manage_forms,
  },
  {
    id: "country_masters",
    prioriy: 4,
    name: "Manage Clients",
    url: Routes.list_countries,
  },
  {
    id: "manage_roles",
    prioriy: 5,
    name: "Manage Roles",
    url: Routes.roles,
  },
  {
    id: "manage_user_groups",
    prioriy: 6,
    name: "Manage User",
    url: Routes.users,
  },
  {
    id: "notify",
    prioriy: 7,
    name: "Notification",
    url: Routes.notify,
  },
  {
    id: "notification",
    prioriy: 8,
    name: "Notification Registry",
    url: Routes.notifications,
  },
  {
    id: "Caseverification",
    prioriy: 9,
    name: "Case Verification",
    url: Routes.verifications,
  },
  {
    id: "investigation",
    prioriy: 11,
    name: "Investigation",
    url: Routes.investigations,
  },
  {
    id: "caseRegistration",
    prioriy: 10,
    name: "Case Registrations",
    url: Routes.case_registration,
  },
  {
    id: "powerLens",
    prioriy: 12,
    name: "Power Lens",
    url: Routes.power_lens,
  },
  {
    id: "r3Menus",
    prioriy: 13,
    name: "R3",
    url: Routes.r3,
  },
  {
    id: "managePatients",
    prioriy: 14,
    name: "Manage Patients",
    url: Routes.manage_patients,
  },
  {
    id: "manageTransferCases",
    prioriy: 15,
    name: "Manage Transfer Cases",
    url: Routes.manage_transfer_cases,
  },
  {
    id: "dashboard",
    prioriy: 1,
    name: "Dashboard",
    url: Routes.dashboard_page,
  },
  {
    id: "home",
    prioriy: 0,
    name: "Home",
    url: Routes.home_page,
  },
  {
    id: "manageClient",
    prioriy: 16,
    name: "Manage Client",
    url: Routes.manage_Client,
  },
  {
    id: "manageFacility",
    prioriy: 17,
    name: "Manage Facility",
    url: Routes.manage_Facility,
  },
  {
    id: "manageLevel",
    prioriy: 18,
    name: "Manage Level",
    url: Routes.manage_level,
  },
  // {
  //     // add client route added
  //     id: 'add_client',
  //     prioriy: 19,
  //     name: 'Add Client',
  //     url: Routes.manage_add_client,
  // },
];

export const genderData = [
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Others",
    label: "Others",
  },
];

export const personalTitles = [
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Miss.",
    label: "Miss.",
  },
  {
    value: "Ms.",
    label: "Ms.",
  },
];
