import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Box, Tooltip } from "@material-ui/core";
import Viewprovider from "./viewprovider";
import Content from "../../components/manageprovider/content";
import Service from "../../components/manageprovider/serviceregistrystepper";
import { withNavBars } from "../../HOCs";
import BasicDetails from "../../components/manageprovider/basicDetails";
import { useDispatch, useSelector } from "react-redux";
import { actions, apis } from "hcc-binder";
import { v4 as uuidV4 } from "uuid";
import { BackdropContext, DrawerContext } from "../../contexts";
import { Message } from "../../utils";
import de from "date-fns/esm/locale/de/index.js";
import { useHistory, useLocation } from "react-router-dom";
import FormRendererParent from "../formRenderer";
import {
  AddCollection,
  AddDatabase,
  AddProject1,
  AddProject2,
  AddProjectconfig,
} from "../../components/manageprovider/apisofaddcontent";
import { envdata } from "../../utils/helperFunctions";
let env = envdata();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  stepper: {
    backgroundColor: "transparent",
    padding: "8px",
    // height: "100%",
  },
  button: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Basic Details", "Add Contents", "Service Detail"];
}
const editcontentypeobjcontrcutor = (data = [], id) => {
  //debugger;
  let filter = data?.filter((v) => {
    return v?.coding?.[0]?._id === id;
  });
  let result = valuelabelfinder(filter);
  return result?.[0];
};

const valuelabelfinder = (data) => {
  try {
    const result = data.map((v) => {
      let obj = {
        label: v?.coding?.[0]?.display,
        value: v?.coding?.[0]?.id,
        _id: v?.coding?.[0]?._id,
      };
      return obj;
    });
    return result;
  } catch (err) {
    return [];
  }
};
function VerticalLinearStepper(props) {
  const classes = useStyles();
  const location = useLocation();
  console.log({ location });
  const isEdit = Boolean(location.state);
  const history = useHistory();
  const dispatch = useDispatch();
  const backdrop = useContext(BackdropContext);
  const drawer = useContext(DrawerContext);
  const selector = useSelector((state) => ({
    getallprovider: state?.providerSlice?.getallprovider.data.result,
    getallcontents: state?.providerSlice?.getcontents?.data?.result,
    getsinglecontent: state?.providerSlice?.getsinglecontent?.data?.result,
  }));
  const { state } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [skipped, setSkipped] = React.useState(new Set());
  const [childrenstate, setChildrenstate] = React.useState({
    // firstName: isEdit ? location?.state?.First_Name : "",
    // lastName: isEdit ? location?.state?.Last_Name : "",
    // email: isEdit ? location?.state?.Email : "",
    // phoneNumber: isEdit ? location?.state?.Phone_Number : "",
    // address: isEdit ? location?.state?.Address : "",
    // pincode: isEdit ? location?.state?.Pincode : "",
    // city: isEdit ? location?.state?.City : "",
    // state: isEdit ? location?.state?.State : "",
    // country: isEdit ? location?.state?.Country : "",
    provider_ID: uuidV4(),
    //for service
    apiname: "",
    httpmethod: {},
    url: "",
    requestschema: "",
    responseschema: "",
    currentContentID: "",
    currentContentName: "",
    currentContentType: "",
    totalContent: [],
    currentlyaddedcontent: [],
  });

  console.log({ childrenstate });
  const handleChange = (key, value, istextarea, fullset) => {
    //debugger;
    try {
      if (typeof value === "string") {
        setChildrenstate({ ...childrenstate, [key]: value });
      } else if (typeof value === "object" && istextarea) {
        let beatifiedvalue = JSON.stringify(value, null, 1);
        setChildrenstate({ ...childrenstate, [key]: beatifiedvalue });
      } else if (typeof value === "object") {
        if (Array.isArray(value)) {
          if (fullset) {
            setChildrenstate({
              ...childrenstate,
              [key]: [...value],
            });
          } else {
            setChildrenstate({
              ...childrenstate,
              [key]: [...childrenstate?.[key], ...value],
            });
          }
        } else {
          setChildrenstate({
            ...childrenstate,
            [key]: { ...childrenstate?.[key], ...value },
          });
        }
      }
    } catch (err) {
      alert("error on object");
      console.log({ err });
    }
  };
  const isStepOptional = (step) => {
    return step === (3 || 4 || 5);
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  React.useEffect(() => {
    //debugger;
    dispatch(actions.getallcontenttype());
    dispatch(actions.getallcontents());
    dispatch(actions.getallstorage());
  }, []);
  React.useEffect(() => {
    if (isEdit) {
      // let filteredcontent = selector?.getallcontents?.filter((v) => {
      //   console.log(
      //     v.Content_ID,
      //     location?.state?.Content_ID?.indexOf(v.Content_ID) > -1
      //   );
      //   return location?.state?.Content_ID?.indexOf(v.Content_ID) > -1;
      // });
      // console.log(
      //   "filteredcontent",
      //   filteredcontent,
      //   location?.state?.Content_ID
      // );
      // setChildrenstate({
      //   ...childrenstate,
      //   totalContent: filteredcontent ?? [],
      // });
    }
  }, [isEdit]);

  React.useEffect(() => {
    setChildrenstate({
      ...childrenstate,
      totalContent: selector.getsinglecontent ?? [],
    });
  }, [selector.getallcontents]);

  // React.useEffect(() => {}, [childrenstate.totalContent]);

  const delecontent = (data) => {
    let __params = {
      activestatus: false,
    };
    let filter = { filter: { _id: `${data?._id}` } };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: Message?.deletecontent,
    });
    let apidata = {
      dbname: env?.REACT_APP_DB,
      entity: "Content",
      ismeta: true,
      metadataid: env?.REACT_APP_METADATAID,
      metadatadbname: env?.REACT_APP_METADATA_DB_NAME,
      filter: filter,
      doc: __params,
    };
    Promise.resolve(apis.UpsertandUpdate(apidata))
      .then((res) => {
        console.log(res);
        drawer.setDrawer({
          open: false,
        });
        dispatch(actions.getallcontents());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlesaveservice = () => {
    //debugger;
    let data = {
      Service_ID: uuidV4(),
      Service_URL: childrenstate.url,
      Method: childrenstate.httpmethod?.value,
      Request_Schema: childrenstate.requestschema,
      Response_Schema: childrenstate.responseschema,
      Api_Name: childrenstate.apiname,
    };
    let params = {
      doc: data,
    };

    Promise.resolve(apis.UpsertandUpdateforservice(params))
      .then((res) => {
        //debugger;
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const handlesaveBasicdeatails = () => {
    //debugger;
    let data = {
      Provider_ID: childrenstate.provider_ID,
      Entry_ID: localStorage.getItem("entry_id"),
    };
    let params = {
      doc: data,
    };

    Promise.resolve(apis.UpsertandUpdateforprovider(params))
      .then((res) => {
        //debugger;
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const handleNext = async () => {
    //debugger;
    performActionOfEachStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const editdelecontent = (index, value) => {
    // let indexarrayvalue = childrenstate?.totalContent?.[index];
    // indexarrayval
  };
  const finisfunction = () => {
    // //debugger;
    // handlesaveservice();
    handlesaveBasicdeatails();
    submitContent();
    history.push("/manageprovider");
  };
  const performActionOfEachStep = async (step) => {
    //debugger;
    switch (step) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      default:
        break;
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          // <BasicDetails state={childrenstate} handleChange={handleChange} />
          <FormRendererParent
            id="c3c176b9-5369-4a71-b0c6-bfa043ad8994"
            secToShow={[0, null]}
            secName={0}
            /* entryId="06c2ceee-4bb6-455a-9116-cf7395e779ec" */
          />
        );
      case 1:
        return (
          <Content
            state={childrenstate}
            submitContent={submitContent}
            handleChange={handleChange}
            editdelefunc={editdelecontent}
          />
        );
      case 2:
        return <Service state={childrenstate} handleChange={handleChange} />;
      default:
        return "Unknown step";
    }
  }

  const validation = (keys) => {
    for (let x of keys) {
      if (childrenstate[keys]?.length === 0) {
        return false;
      }
    }
    return true;
  };

  const submitContent = async () => {
    // debugger;
    // const isValidated = validation();
    // if (!isValidated) {
    //   return false;
    // }

    let __params = [];
    childrenstate.totalContent.forEach((v) => {
      debugger;
      let obj = {
        Content_ID: v.Content_ID,
        Content_Name: v.Content_Name,
        Content_Type: v.Content_Type,
        Storage: v.Storage,
        Provider_ID: childrenstate.provider_ID,
      };

      if (Boolean(v?.isnew)) {
        __params.push(obj);

        let addproject1payload = {
          client: "sweeety",
          db_name: "undefined",
          projectname: v.Content_Name?.trim(),
          storagetype: "Arango",
          description: "autogenerated",
          uploadurl: env?.REACT_APP_Arrango_Uploadurl,
          metadataId: "",
          metadataname: v.Content_Name?.trim(),
          attributes: [
            {
              theme: "",
              user: "",
            },
          ],
          metadata: [],
          entity_relationship: [],
        };
        let addDbPayload = {
          db_name: `sweeety_${v.Content_Name?.trim()}`,
        };
        let addprojectconfig = {
          projectName: v.Content_Type,
          clientName: "sweety",
          dataStorage: `sweeety_${v.Content_Name?.trim()}`,
          connectionParams: {
            ArangoUrl: env?.REACT_APP_Arrango_Uploadurl,
          },
          type: "Arango",
        };
        // AddProject1(addproject1payload);
        // AddProject2();
        AddDatabase(addDbPayload);
        AddCollection(addDbPayload);
        // await AddProjectconfig(addprojectconfig);
      } else if (Boolean(v?.isedit)) {
        __params.push(obj);
      }
    });
    console.log({ __params });

    // let __params = {
    //   Content_ID: uuidV4(),
    //   Content_Name: state.contentName,
    //   Content_Type: state.contentType?._id,
    // };

    let filter = {
      filter: {
        Provider_ID: `${location.state?.Providerdetails?.Provider_ID}`,
      },
    };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: props.isEdit ? Message.updatingcontent : Message.addcontent,
    });

    let apidata = {
      dbname: env?.REACT_APP_DB,
      entity: "Content",
      ismeta: true,
      metadataid: env?.REACT_APP_METADATAID,
      metadatadbname: env?.REACT_APP_METADATA_DB_NAME,
      filter: props?.isEdit ? filter : undefined,
      doc: __params,
    };
    Promise.resolve(apis.UpsertandUpdate(apidata))
      .then((res) => {
        console.log(res);
        drawer.setDrawer({
          open: false,
        });
        dispatch(actions.getallcontents());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("icid", childrenstate);
  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        style={{ height: "52px", padding: "0px" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        style={{
          height: "calc(100% - 142px)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        {getStepContent(activeStep, state)}
      </Box>
      <Box
        style={{
          display: "flex",
          height: "52px",
          alignItems: "center",
        }}
      >
        <div style={{ flex: "1 1 auto" }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {/* {isStepOptional(activeStep) && (
          <Button
            color="inherit"
            // onClick={handleSkip}
          >
            Skip
          </Button>
        )}

        {activeStep === 0 && (
          <Tooltip title="Update Client Details" placement="top" arrow>
            <Button
              variant="contained"
              color="primary"
              // onClick={() => upsertUpdateClient()}
              style={{ marginRight: "4px" }}
            >
              Update
            </Button>
          </Tooltip>
        )} */}
        <Button
          variant="contained"
          color="primary"
          onClick={activeStep === steps.length - 1 ? finisfunction : handleNext}
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </div>
  );
}
export default withNavBars(VerticalLinearStepper);
