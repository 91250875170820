/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Switch,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Divider,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
// import styles from "./styles";
import DeleteIcon from "../../assets/icons8-trash.svg";
import { withStyles } from "@material-ui/core/styles";
// import searchicon from "../../assets/icons - Search.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { AlertContext, BackdropContext, DrawerContext } from "../../contexts";
import Addcontent from "./addcontent";
import { actions, apis } from "hcc-binder";
import { Message } from "../../utils";
import { AlertProps } from "../../utils/constants";
import SearchIcon from "@material-ui/icons/Search";

import { envdata } from "../../utils/helperFunctions";
let env = envdata();


const styles = makeStyles((theme) => ({
  editButton: {
    marginLeft: "10px",
    backgroundColor: "#0071F2",
    borderRadius: "0px",
    color: "#fff",
  },
  drawerHead: {
    display: "flex",
    padding: "25px",
    borderBottom: "1px solid #eee",
  },
  drawerSubHead: {
    fontFamily: "poppin",
    color: "#6F6F6F",
    fontSize: "12px",
    paddingBottom: "8px",
  },
  addDrawerBtn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    fontFamily: "poppin",
    textTransform: "none",
    padding: "8px 60px",
    marginTop: "20px",
  },
  textTotalArea: {
    padding: "20px 30px",
  },
  headerMain: {
    //fontFamily: "poppinsemibold",
  },
  btnPosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  helperText: {
    color: "#ff0000",
  },
  root: {
    borderRadius: "0px",
    width: "100%",
  },
  firstSec: {
    padding: "15px 45px",
    borderBottom: "3px solid #9b9999",
  },
  firstSecRepository: {
    padding: "15px 45px",
  },
  btn: {
    //backgroundColor: "#0071F2",
    color: "#fff",
    padding: "6px 16px",
    // fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
  },
  rolesDisplay: {
    width: "auto",
    border: "1px solid #DCDCDC",
    display: "flex",
    padding: "10px 30px",
    marginBottom: "10px",
    borderRadius: "8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  lowerDisplay: {
    width: "99%",
    border: "1px solid #DCDCDC",
    display: "flex",
    padding: "14px 16px",
    borderRadius: "8px",
    alignItems: "center",
  },
  secondSec: {
    padding: "20px 45px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      background: "#0071F2",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #f4f4f5",
    },
  },
  tabEdit: {
    textTransform: "none",
  },
  Section2: {
    height: `calc(100vh - 452px)`,
    overflow: "auto",
    paddingBottom: "25px",
  },
  Section2NoMain: {
    height: `calc(100vh - 192px)`,
    overflow: "auto",
    paddingBottom: "25px",
  },

  mainAreaRepository: {
    height: `calc(100vh - 216px)`,
    overflow: "auto",
    margin: "10px 0px",
    paddingBottom: "20px",
  },
  mainArea: {
    height: "250px",
    overflow: "auto",
    marginTop: "10px",
  },
  mainAreaNoData: {
    height: "0px",
  },
  selectedCell: {
    cursor: "pointer",
    border: "1px solid #0071F2",
    width: "99%",
    display: "flex",
    padding: "14px 30px",
    marginBottom: "10px",
    borderRadius: "8px",
  },
  numbersEdit: {
    marginTop: "4px",
    //fontFamily: "poppinsemibold",
    padding: "10px 20px 6px",
    textAlign: "center",
  },
  paperEdit: {
    borderRadius: "0px",
  },
  innerText: {
    fontSize: "12px",
    width: "100%",
    //fontFamily: "poppinsemibold",
  },
  innerText1: {
    fontSize: "14px !important",
    fontWeight: "450",
  },

  descriptionTab: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #DCDCDC",
    padding: "9px 60px 9px 30px",
    borderRadius: "8px",
    backgroundColor: "#f6f6f6",
  },

  table: {
    minWidth: 650,
  },
  tableHeader: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontFamily: "poppin",
    fontSize: "12px",
    color: "gray",
    backgroundColor: "#f9f9f9",
  },
  tableBody: {
    width: "120px",
    padding: "12px",
    //fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  tableContainer: {
    borderRadius: "8px",
    marginTop: "20px",
    boxShadow: "none",
    border: "1px solid #E0E0E0",
    height: `calc(100vh - 218px)`,
  },
  repoLeftSide: {
    height: `calc(100vh - 143px)`,
    borderRadius: "10px",
    backgroundColor: "#fff",
    border: "1px solid #DCDCDC",
  },
  active: {
    backgroundColor: "#E0EBF9 !important",
    color: "#0071F2 !important",
  },
  groupedBtnAlign: {
    padding: "5px",
    margin: "auto",
    display: "flex",
    justifyContent: "flex-end",
  },

  rolesChipRoot: {
    borderRadius: 4,
    height: 26,
    color: theme.palette.text.primary,
    //fontFamily: "poppinsemibold",
    background: "#F0F0F0",
  },
  autocompleteTag: {
    borderRadius: 8,
    height: 26,
    color: theme.palette.text.primary,
    //fontFamily: "poppinsemibold",
    background: "#F0F0F0",
    "& .MuiChip-label": {
      paddingRight: 24,
    },
  },

  boxFirstDiv: {
    marginRight: "2%",
    marginBottom: "2%",
  },
  detailBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "1%",
    color: "#6f6f6f",
  },
  iconStyle: {
    fontSize: 18,
    color: "#0071F2",
    marginRight: 6,
  },
  detailData: {
    marginLeft: "18px",
    textAlign: "left",
    //fontFamily: "poppinsemibold",
    fontSize: "0.9rem",
  },
  displaySectionTitle: {
    //fontFamily: "poppinsemibold",
    padding: "10px 6px",
    display: "inline-block",
  },
  nodatafound: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
}));

function Content(props) {
  const [currentcarddata, setCurrentcarddata] = useState({});
  const selector = useSelector((state) => ({
    getallcontenttype: state?.providerSlice?.getallcontenttype.data.result,
    getallstorage: state?.providerSlice?.getallstorage?.data?.result,
    getallcontents: state?.providerSlice?.getcontents?.data?.result,
    getsinglecontent: state?.providerSlice?.getsinglecontent?.data?.result,
  }));
  const [load, setLoad] = useState(false);
  const selectorforloading = useSelector((state) => ({
    getsinglecontent: state?.providerSlice?.getsinglecontent,
  }));
  const alertContext = React.useContext(AlertContext);
  // React.useEffect(() => {
  //   console.log(selector.getallcontents);
  // }, []);

  console.log({ props });
  const dispatch = useDispatch();
  const classes = styles();
  const drawer = useContext(DrawerContext);
  const backdrop = useContext(BackdropContext);
  const [filteredData, setFilteredData] = useState([]);

  React.useEffect(() => {
    debugger;
    setFilteredData(selector.getsinglecontent ?? []);
    setCurrentcarddata(selector.getsinglecontent?.[0] ?? {});
    // document.cookie = `project_id=${selector.getsinglecontent?.[0].Provider_Id}; client_id=${selector.getsinglecontent?.[0].Content_ID}; isfallback=true; SameSite=None  `;
    document.cookie = `Content_ID=${
      selector.getsinglecontent?.[0]?.Content_ID ?? ""
    }; `;
    document.cookie = `Provider_Id=${
      selector.getsinglecontent?.[0]?.Provider_Id ?? ""
    }; `;
    document.cookie = "ishcc=true;";
    console.log("cookies", document.cookie);
  }, [selector.getsinglecontent]);

  React.useEffect(() => {
    dispatch(actions.getallcontenttype());
    dispatch(actions.getsinglecontent(props?.state?.Provider_Id));
    dispatch(actions.getallstorage());
  }, []);
  const addfunc = (data, isedit) => {
    debugger;
    drawer.setDrawer({
      open: true,
      component: (
        <Addcontent
          contenttype={selector?.getallcontenttype}
          storage={selector?.getallstorage ?? []}
          editData={data}
          isEdit={isedit}
          providerstate={props?.state}
        />
      ),
    });
  };

  const editcontentypeobjcontrcutor = (data = [], id) => {
    debugger;
    let filter = data?.filter((v) => {
      return v?.coding?.[0]?._id === id;
    });
    return filter[0]?.coding?.[0]?.code;
  };

  const handleChange = (key, value, istextarea, fullset) => {
    //debugger;
    try {
      if (typeof value === "string") {
        setChildrenstate({ ...childrenstate, [key]: value });
      } else if (typeof value === "object" && istextarea) {
        let beatifiedvalue = JSON.stringify(value, null, 1);
        setChildrenstate({ ...childrenstate, [key]: beatifiedvalue });
      } else if (typeof value === "object") {
        if (Array.isArray(value)) {
          if (fullset) {
            setChildrenstate({
              ...childrenstate,
              [key]: [...value],
            });
          } else {
            setChildrenstate({
              ...childrenstate,
              [key]: [...childrenstate?.[key], ...value],
            });
          }
        } else {
          setChildrenstate({
            ...childrenstate,
            [key]: { ...childrenstate?.[key], ...value },
          });
        }
      }
    } catch (err) {
      alert("error on object");
      console.log({ err });
    }
  };

  const searchfunc = (e) => {
    debugger;
    let value = e.target.value;
    if (value) {
      let filter = selector.getsinglecontent?.filter((v) => {
        debugger;
        return (
          v?.Content_Name?.toLowerCase()?.includes(value.toLowerCase()) ||
          v?.Content_Type?.label?.toLowerCase()?.includes(value.toLowerCase())
        );
      });
      setFilteredData(filter);
    } else {
      setFilteredData(selector.getsinglecontent);
    }
  };

  const delefunc = (data) => {
    let __params = {
      activestatus: false,
    };
    let filter = { filter: { _id: `${data?._id}` } };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: Message?.deletecontent,
    });
    let apidata = {
      dbname: env?.REACT_APP_DB,
      entity: "Content",
      ismeta: true,
      metadataid: env?.REACT_APP_METADATAID,
      metadatadbname: env?.REACT_APP_METADATA_DB_NAME,
      filter: filter,
      doc: __params,
    };
    Promise.resolve(apis.UpsertandUpdate(apidata))
      .then((res) => {
        console.log(res);
        drawer.setDrawer({
          open: false,
        });
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.success,
          msg: Message.delete_content_success,
          horizontal: AlertProps.horizontal.right,
          vertical: AlertProps.vertical.top,
        });
        dispatch(actions.getsinglecontent(props?.state?.Provider_Id));
      })
      .catch((err) => {
        console.log(err);
      });
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };
  const handleload = (e) => {
    console.log("load", e);
    setLoad(true);
  };
  const handleclick = (e, item) => {
    debugger;
    setCurrentcarddata(item);
    setLoad(false);
    document.cookie = `Provider_Id=${item.Provider_Id};    `;
    document.cookie = `Content_ID=${item.Content_ID};`;
    document.cookie = "ishcc=true;";
    console.log("cookies", document.cookie);
  };
  return (
    <div>
      <Grid container direction="row" style={{ padding: "10px 0px" }}>
        <Grid item xs={3}>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid #DCDCDC",
              borderRadius: "10px 10px 0px 0px",
              padding: "10px",
            }}
          >
            <div>
              {/* ------------------------------Title---------------------- */}
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <Typography
                  style={{
                    marginTop: "4px",
                    fontWeight: "500",
                    fontSize: "1 rem",
                  }}
                >
                  Manage Content
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                <Button color="primary" variant="contained" className={classes?.btn} onClick={addfunc} contained>
                  + Add
                </Button>
              </div>
              {/* -----------------------------Search---------------------------- */}
              <div>
                <TextField
                  id="outlined-search"
                  placeholder="Search field"
                  onChange={searchfunc}
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  type="search"
                  variant="outlined"
                  // fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "",
                      fontSize: "12px",
                      background: "#f6f6f6",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid #DCDCDC",
              borderRadius: "0px 0px 10px 10px",
              padding: "10px ",
            }}
          >
            <div>
              {/* <div className={classes?.descriptionTab}>
                <Typography
                  className={classes?.innerText1}
                  style={{
                    color: "#0000008a",
                  }}
                >
                  Contents
                </Typography>
                <Typography
                  className={classes?.innerText1}
                  style={{
                    color: "#0000008a",
                  }}
                >
                  Actions
                </Typography>
              </div> */}
              <div
                className={classes?.mainArea}
                style={{
                  height: `calc(100vh - 315px)`,
                }}
              >
                {selectorforloading.getsinglecontent?.loading ? (
                  <div style={{ textAlign: "center", paddingTop: "90px" }}>
                    <CircularProgress />
                  </div>
                ) : filteredData?.length > 0 ? (
                  filteredData?.map((item, index) => (
                    <div style={{ width: "100%", cursor: "pointer" }}>
                      <div
                        className={
                          currentcarddata?._id === item?._id
                            ? classes?.selectedCell
                            : classes?.rolesDisplay
                        }
                        onClick={(e) => {
                          handleclick(e, item);
                        }}
                      >
                        <Typography className={classes?.innerText}>
                          {/* {item?.email} */}
                          {item?.Content_Name}
                        </Typography>
                        <div style={{ flexGrow: 1 }}></div>
                        {/* <IOSSwitch
                        checked={item?.active === true ? true : false}
                        onChange={(e) => handlePermissionStatus(e, item)}
                      /> */}
                        <img
                          src={`/images/icons8-edit.svg`}
                          onClick={() => addfunc(item, true)}
                          width="12px"
                          style={{ margin: "0px 16px" }}
                        />
                        <img
                          src={DeleteIcon}
                          onClick={() => delefunc(item)}
                          width="12px"
                          style={{ margin: "0px 16px" }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ width: "100%" }}>
                    <Typography
                      className={classes?.innerText}
                      align="center"
                      alignItems="center"
                    >
                      sry data not found !
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid>
        {Object.keys(currentcarddata).length > 0 ? (
          <Grid
            item
            container
            xs={9}
            style={{ padding: "0px 0px 0px 10px" }}
            justifyContent="center"
            alignItems="center"
          >
         
            <iframe
              src={`https://ainqarefine.dev.ainqaplatform.in/?ProjectId=${
                currentcarddata.Content_ID
              }&ProjectName=${currentcarddata.Content_Name}&ClientName=${
                props.state?.["Providers Name"]
              }&ClientId=${
                currentcarddata.Provider_Id
              }&StorageType=${editcontentypeobjcontrcutor(
                selector.getallstorage,
                currentcarddata?.Storage
              )}&contentid=${
                currentcarddata?._id
              }&ishcc=true`}
              onLoad={() => handleload()}
              style={{
                width: !load ? "0%" : "100%",
                height: !load ? "0%" : "99%",
                border: "none",
                display: Object.keys(currentcarddata).length > 0 ? "" : "none",
                visibility: !load && "hidden",
              }}
              frameBorder="0"
              allowFullScreen
            />

            {!load && (
              <Grid container item justifyContent="center">
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
                <Grid container justifyContent="center">
                  <Typography>loading refine</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={9}
            style={{ padding: "0px 0px 0px 10px" }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Please add content inorder to view datum</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
export default Content;
